import { atom } from 'recoil';

interface AuthState {
  accessToken: string;
}

export const authState = atom<AuthState>({
  key: 'authState',
  default: {
    accessToken: '',
  },
});
