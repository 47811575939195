import { useMemo } from 'react';

import { Stack, useTheme } from '@mui/joy';
import EChartsReact from 'echarts-for-react';

const ProjectVsImpactScoreGraph = ({
  data,
  rating,
}: {
  data: { sdg: string; count: number }[];
  rating: number;
}) => {
  const theme = useTheme();
  const option = useMemo(() => {
    const series = data.map((value) => ({
      value: [parseInt(value.sdg, 10) - 1, value.count],
      name: `${rating}` === value.sdg ? 'This project' : '',
      itemStyle: {
        color:
          `${rating}` === value.sdg
            ? theme.palette['GHG']['C+']
            : theme.palette['border']['border'],
        borderRadius: [3, 3, 0, 0],
      },
    }));
    return {
      backgroundColor: 'transparent',
      legend: {
        data: [{ name: 'This project' }],
        bottom: 0, // Adjust the value as needed
        left: 10, // Adjust the value as needed
        icon: 'circle', // Use circle for rounded icons
        textStyle: {
          color: theme.palette.text.primary,
          fontSize: '12px',
          fontFamily: "'Inter Variable', sans-serif",
        },
      },
      tooltip: {
        position: 'top',
        trigger: 'item',
        formatter: function (params) {
          const { value } = params;
          return `${value[1]} Projects`;
        },
      },
      xAxis: {
        type: 'category',
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        name: 'Impact Score',
        nameTextStyle: {
          fontSize: 11,
          color: theme.palette.text.secondary,
          // fontWeight: 'bold'
        },
        axisTick: {
          show: false,
        },
        nameLocation: 'middle',
        nameGap: 36,
        axisLabel: {
          textStyle: {
            fontFamily: "'Inter Variable', sans-serif",
          },
        },
      },
      yAxis: {
        type: 'value',
        name: 'Projects (this type)',
        // splitNumber:4,
        min: 0,
        minInterval: 5,
        splitLine: {
          lineStyle: {
            color: '#3A3B3C',
          },
        },
        nameTextStyle: {
          fontSize: 11,
          color: theme.palette.text.secondary,
          // fontWeight: 'bold'
        },
        nameLocation: 'middle',
        nameGap: 36,
        axisLabel: {
          textStyle: {
            fontFamily: "'Inter Variable', sans-serif",
          },
        },
      },
      series: [
        {
          name: 'This project',
          barWidth: '40%',
          barCategoryGap: '1%',
          data: series,
          type: 'bar',
          itemStyle: {
            normal: {
              barBorderRadius: [50, 50, 0, 0],
              color: '#FFCB14',
            },
          },
        },
      ],
      grid: {
        left: 64,
        bottom: 64,
      },
    };
  }, [theme, data, rating]);

  return (
    <Stack
      sx={{
        marginLeft: { xs: 'auto', lg: '' },
        width: { xs: '360px' },
        height: { xs: '300px' },
      }}
    >
      <EChartsReact style={{ width: '100%', height: '100%', marginTop: '-40px' }} option={option} />
    </Stack>
  );
};

export default ProjectVsImpactScoreGraph;
