import axios from 'axios';
import { getRecoil } from 'recoil-nexus';

import { appState } from '../../stores/app';
import { authState } from '../../stores/auth';

const axiosInstance = axios.create({ baseURL: process.env.BASE_URL });
const authenticatedAxiosInstance = axios.create({ baseURL: process.env.BASE_URL });

authenticatedAxiosInstance.interceptors.request.use((config) => {
  const { accessToken } = getRecoil(authState);
  const appStateValues = getRecoil(appState);
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
    if (appStateValues.isStaging) {
      config.headers['projectWorkflowState'] = 'staging';
    } else {
      delete config.headers['projectWorkflowState'];
    }
  }
  return config;
});

authenticatedAxiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config } = error;
    if (error.response?.status === 401 && !config._retry) {
      config._retry = true;
      return authenticatedAxiosInstance(config);
    }
    return Promise.reject(error);
  }
);

export const makeGetRequest = axiosInstance.get;
export const makePostRequest = axiosInstance.post;
export const makePutRequest = axiosInstance.put;
export const makeDeleteRequest = axiosInstance.delete;

export const makeAuthenticatedGetRequest = authenticatedAxiosInstance.get;
export const makeAuthenticatedPostRequest = authenticatedAxiosInstance.post;
export const makeAuthenticatedPutRequest = authenticatedAxiosInstance.put;
export const makeAuthenticatedDeleteRequest = authenticatedAxiosInstance.delete;
