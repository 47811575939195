import React, { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';

import { Sheet, Skeleton, Stack, Typography, useTheme } from '@mui/joy';
import { Virtualizer } from '@tanstack/react-virtual';
import { uniqueId } from 'lodash';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';

import { PaginationState } from '../../utils/hooks/usePagination/types';

import Cell from './Cell';
import Column from './Column';
import Footer from './Footer';
import Row from './Row';
import { StyledFooter, StyledTBody, StyledTHead, StyledTable } from './styles';
import { Cell as CellType, Data } from './types';

type TableProps = {
  bodyStyle?: CSSProperties;
  columns: CellType[][];
  data: Data[];
  cellRenderOrder: string[];
  isLoading?: boolean;
  paginationState: PaginationState;
  isStickyHeader?: boolean;
  isStickyFooter?: boolean;
  hasInfiniteData?: boolean;
  footerCells?: CellType[];
  error?: ReactNode;
  loaderRef?: React.MutableRefObject<HTMLDivElement | null>;
  rowVirtualizer?: Virtualizer<Element, Element>;
  noDataMessage?: ReactNode;
  onRowClick?: (row: Data) => void;
  customCell?: (data: Data, key: string) => React.ReactNode;
  customContent?: (data: Data, key: string) => React.ReactNode;
  toggleSort: (key: string) => void;
  getSortValue: (key: string) => any;
  isRowDiabled?: (row: Data) => boolean;
  onRowMouseLeave?: () => void;
};

const Table: React.FC<TableProps> = ({
  bodyStyle,
  columns,
  data = [],
  isLoading,
  cellRenderOrder,
  paginationState,
  isStickyHeader,
  isStickyFooter,
  hasInfiniteData,
  footerCells,
  rowVirtualizer,
  error,
  loaderRef,
  noDataMessage,
  onRowClick,
  customCell,
  toggleSort,
  getSortValue,
  isRowDiabled,
  customContent,
  onRowMouseLeave,
}) => {
  const parentRef = useRef(null);
  const noResultsFound = useMemo(() => data?.length === 0 && !isLoading, [data.length, isLoading]);
  const columnsValueMap = useRef(new Map());
  const hasPinnedColumn = useRef(false);
  const theme = useTheme();

  // set columns value map
  useEffect(() => {
    const map = new Map();
    columns.flat().forEach((column) => {
      map.set(column.key, column);
      if (column.isPinned) {
        hasPinnedColumn.current = true;
      }
    });
    columnsValueMap.current = map;
  }, []);

  const renderCell = useCallback(
    (data: Data) => {
      const keys = cellRenderOrder;
      const fallBack = (key: string) => {
        const contentStyle = columnsValueMap.current.get(key)?.contentStyle;
        return (
          <Typography
            level='body-xs'
            fontWeight={600}
            sx={{
              color: (theme) => theme.palette.text.primary,
              ...(contentStyle || {}),
            }}
          >
            {customContent ? customContent(data, key) : data[key]}
          </Typography>
        );
      };
      return keys.map((key, index) => {
        const cellStyle = columnsValueMap.current.get(key)?.cellStyle;
        const isPinned = columnsValueMap.current.get(key)?.isPinned;
        return (
          <Cell
            key={uniqueId('cell_')}
            style={{
              borderLeft: index <= 1 || index === keys.length - 1 ? 'inhereit' : 'none',
              background: isPinned ? theme.palette.background.body : '',
              ...(cellStyle || {}),
            }}
          >
            {customCell ? customCell(data, key) || fallBack(key) : fallBack(key)}
          </Cell>
        );
      });
    },
    [customCell, columnsValueMap.current, theme]
  );

  const renderEmptyCell = useCallback(
    (count: number) => {
      const dummy = [...new Array(count)].fill('');
      return dummy.map((_) => (
        <Row key={uniqueId('dummyrow')}>
          {cellRenderOrder.map((key, index) => (
            <Cell
              key={uniqueId('dummycell_')}
              style={{
                borderLeft: index <= 1 || index === dummy.length - 1 ? 'inhereit' : 'none',
                background: columnsValueMap.current.get(key)?.isPinned
                  ? theme.palette.background.body
                  : '',
                ...(columnsValueMap.current.get(key)?.cellStyle || {}),
              }}
            >
              <Skeleton sx={{ position: 'relative' }} />
            </Cell>
          ))}
        </Row>
      ));
    },
    [theme]
  );

  const renderColumnCell = useCallback(
    (columns: CellType[][], isFooter?: boolean) => {
      return columns.map((column) => (
        <Row key={uniqueId('column')} style={{ borderRadius: 0, display: isFooter ? 'flex' : '' }}>
          {column.map((cell) =>
            cell.isLoading ? (
              <Column
                rowSpan={cell.rowSpan}
                colSpan={cell.colSpan}
                key={cell.key}
                style={{
                  background: cell.isPinned ? theme.palette.background.body : '',
                  ...(cell?.cellStyle || {}),
                }}
              >
                <Skeleton sx={{ position: 'relative' }} />
              </Column>
            ) : (
              <Column
                rowSpan={cell.rowSpan}
                colSpan={cell.colSpan}
                key={cell.key}
                isSortable={cell.isSortable}
                style={{
                  cursor: cell.isSortable ? 'pointer' : 'default',
                  background: cell.isPinned ? theme.palette.background.body : '',
                  ...(cell?.cellStyle || {}),
                }}
                onClick={() => toggleSort(cell.key)}
              >
                <Stack direction={'row'} alignItems={'center'} sx={cell.contentStyle}>
                  {cell.isSortable && (
                    <>
                      {getSortValue(cell.key) === 'ASC' && <BiSolidUpArrow size={10} />}
                      {getSortValue(cell.key) === 'DESC' && <BiSolidDownArrow size={10} />}
                    </>
                  )}
                  <Typography level='body-xs' fontWeight={700} color='primary' sx={{ ml: 1 }}>
                    {cell.name}
                  </Typography>
                </Stack>
              </Column>
            )
          )}
        </Row>
      ));
    },
    [getSortValue, toggleSort, theme]
  );

  useEffect(() => {
    paginationState.setScrollParentRef(parentRef.current);
  }, [parentRef.current]);

  return (
    <StyledTable
      borderAxis='both'
      hoverRow
      stickyHeader={isStickyHeader}
      stickyFooter={isStickyFooter}
      sx={{ display: 'table-row' }}
    >
      <Sheet sx={hasPinnedColumn.current ? { overflow: noResultsFound ? 'hidden' : 'auto' } : {}}>
        <StyledTHead sx={hasPinnedColumn.current ? { position: 'sticky', top: 0, zIndex: 12 } : {}}>
          {renderColumnCell(columns)}
        </StyledTHead>
        <StyledTBody
          style={bodyStyle}
          ref={parentRef}
          sx={{
            height: `${rowVirtualizer?.getTotalSize()}px`,
            position: 'relative',
            overflowY: hasPinnedColumn.current ? '' : 'scroll',
            borderBottom: (theme) =>
              error || noResultsFound
                ? `1px solid ${theme.palette['border']['border']}`
                : 'inherit',
            borderBottomRightRadius: (theme) => (error || noResultsFound ? theme.radius.sm : ''),
            borderBottomLeftRadius: (theme) => (error || noResultsFound ? theme.radius.sm : ''),
          }}
        >
          {error ? (
            error
          ) : (
            <>
              {rowVirtualizer
                ? rowVirtualizer.getVirtualItems().map((virtualRow) => {
                    const isLoaderRow = virtualRow.index > data.length - 1;
                    const isLast = virtualRow.index === data.length;
                    const row = data[virtualRow.index];
                    const isDisabled = isRowDiabled && isRowDiabled(data[virtualRow.index]);
                    if (isLoaderRow) {
                      if (data.length >= paginationState.limit)
                        return (
                          <Row
                            data-index={virtualRow.index}
                            ref={(node) => {
                              rowVirtualizer.measureElement(node);
                              if (loaderRef && isLast) loaderRef.current = node;
                            }}
                            key={uniqueId('virtual_row')}
                            style={{
                              position: 'absolute',
                              transform: `translateY(${virtualRow.start}px)`,
                            }}
                          >
                            {cellRenderOrder.map((key, index) => (
                              <Cell
                                key={uniqueId('cell')}
                                style={{
                                  borderLeft: index <= 1 || index === 0 ? 'inhereit' : 'none',
                                  paddingRight: index === cellRenderOrder.length - 1 ? '5px' : '',
                                  background: columnsValueMap.current.get(key)?.isPinned
                                    ? theme.palette.background.body
                                    : '',
                                  ...(columnsValueMap.current.get(key)?.cellStyle || {}),
                                }}
                              >
                                <Skeleton sx={{ position: 'relative' }} />
                              </Cell>
                            ))}
                          </Row>
                        );
                      else {
                        return null;
                      }
                    }
                    return (
                      <Row
                        onClick={() => {
                          onRowClick && onRowClick(row);
                        }}
                        key={uniqueId('row')}
                        data-index={virtualRow.index}
                        ref={(node) => rowVirtualizer.measureElement(node)}
                        className={isDisabled ? 'disabled' : ''}
                        style={{
                          position: 'absolute',
                          transform: `translateY(${virtualRow.start}px)`,
                        }}
                        onMouseLeave={isDisabled ? onRowMouseLeave && onRowMouseLeave : undefined}
                      >
                        {renderCell(row)}
                      </Row>
                    );
                  })
                : isLoading
                  ? renderEmptyCell(paginationState.limit)
                  : data.map((row) => {
                      const isDisabled = isRowDiabled && isRowDiabled(row);
                      return (
                        <Row
                          className={isDisabled ? 'disabled' : ''}
                          onClick={() => onRowClick && onRowClick(row)}
                          onMouseLeave={isDisabled ? onRowMouseLeave && onRowMouseLeave : undefined}
                          key={uniqueId('row_')}
                        >
                          {renderCell(row)}
                        </Row>
                      );
                    })}
              {isLoading && data.length === 0 && renderEmptyCell(paginationState.limit)}
              {noResultsFound && noDataMessage}
            </>
          )}
        </StyledTBody>
      </Sheet>
      {hasInfiniteData ? (
        footerCells &&
        !error &&
        !noResultsFound && (
          <StyledFooter sx={{ pr: '7px' }}>{renderColumnCell([footerCells], true)}</StyledFooter>
        )
      ) : noResultsFound ? null : (
        <Footer paginationState={paginationState} span={7} isLoading={isLoading} />
      )}
    </StyledTable>
  );
};

export default Table;
