import React from 'react';

import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/joy';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { MdKeyboardArrowDown } from 'react-icons/md';

import { annualCarbonCreditsOptions } from '../constants';
import { ErrorType, SignUpInfo } from '../SignUp';

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  fontSize: theme.vars.fontSize.sm,
  fontWeight: 'normal',
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: theme.vars.fontSize.sm,
  fontWeight: theme.vars.fontWeight.md,
}));

const OrganisationForm: React.FC<{
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error: ErrorType;
  data: SignUpInfo;
}> = ({ handleChange, error, data }) => {
  const theme = useTheme();
  return (
    <>
      <Typography fontSize={'xl'} fontWeight={'xl'} color='primary'>
        Enter your organisation’s details
      </Typography>
      <FormControl error={!!error.organisation} onChange={handleChange}>
        <StyledFormLabel>Organisation</StyledFormLabel>
        <Input type='name' name='organisation' defaultValue={data.organisation} />
        {error.organisation && (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <IoMdInformationCircleOutline
              size={14}
              style={{ marginTop: '6px', color: theme.vars.palette.danger[500] }}
            />
            <StyledFormHelperText>{error.organisation}</StyledFormHelperText>
          </Stack>
        )}
      </FormControl>
      <FormControl error={!!error.role} onChange={handleChange}>
        <StyledFormLabel>Role</StyledFormLabel>
        <Input type='name' name='role' defaultValue={data.role} />
        {error.role && (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <IoMdInformationCircleOutline
              size={14}
              style={{ marginTop: '6px', color: theme.vars.palette.danger[500] }}
            />
            <StyledFormHelperText>{error.role}</StyledFormHelperText>
          </Stack>
        )}
      </FormControl>
      <FormControl error={!!error.annualCarbonCredits}>
        <StyledFormLabel>
          Estimated annual number of carbon credits purchased by your organisation
        </StyledFormLabel>
        <Select
          name='annualCarbonCredits'
          indicator={<MdKeyboardArrowDown />}
          defaultValue={!data.annualCarbonCredits ? null : data.annualCarbonCredits}
          onChange={(_, value) =>
            handleChange({
              target: { name: 'annualCarbonCredits', value },
            } as React.ChangeEvent<HTMLInputElement>)
          }
        >
          {annualCarbonCreditsOptions.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
        {error.annualCarbonCredits && (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <IoMdInformationCircleOutline
              size={14}
              style={{ marginTop: '6px', color: theme.vars.palette.danger[500] }}
            />
            <StyledFormHelperText>{error.annualCarbonCredits}</StyledFormHelperText>
          </Stack>
        )}
      </FormControl>
    </>
  );
};

export { OrganisationForm };
