import { useState, useCallback, useRef } from 'react';

const useDebouncedInput = (initalValue, callback, delay = 1000) => {
  const [input, setInput] = useState(initalValue);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debounce = useCallback(
    (value) => {
      // Clear the previous timeout if any
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Set a new timeout
      timeoutRef.current = setTimeout(() => {
        callback(value);
      }, delay);
    },
    [callback, delay]
  );

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value;
      setInput(value);
      debounce(value);
    },
    [debounce]
  );

  return [input, handleChange, setInput];
};

export default useDebouncedInput;
