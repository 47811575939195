import React from 'react';

import { Snackbar } from '@mui/joy';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { snackbarState } from '../stores/snackerbar';

const SnackBar: React.FC = () => {
  const snackbar = useRecoilValue(snackbarState);
  const setSnackbar = useSetRecoilState(snackbarState);

  const handleClose = () => {
    setSnackbar({ message: '' });
  };

  return (
    <Snackbar
      open={!!snackbar.message}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={3000}
      color={snackbar.color || 'neutral'}
      variant='outlined'
    >
      {snackbar.message}
    </Snackbar>
  );
};

export default SnackBar;
