import { useCallback, useRef } from 'react';

import { useGridFilter } from 'ag-grid-react';

const DateFilterAGGrid = ({
  model,
  onModelChange,
  getValue,
}: {
  model: any;
  onModelChange: (value: string | null) => void;
  getValue: (node: any) => any;
}) => {
  const refInput = useRef<HTMLInputElement>(null);

  const doesFilterPass = useCallback(
    (params: any) => {
      const { node } = params;
      const filterText = model;
      const { start, end } = getValue(node) ?? { start: 0, end: 0 };
      return (
        Number(start) <= new Date(filterText).getFullYear() &&
        new Date(filterText).getUTCFullYear() <= Number(end)
      );
    },
    [model]
  );

  const afterGuiAttached = useCallback((params: any) => {
    if (!params || !params.suppressFocus) {
      refInput.current?.focus();
    }
  }, []);

  useGridFilter({
    doesFilterPass,
    afterGuiAttached,
  });

  return (
    <div style={{ paddingLeft: '16px' }}>
      <input
        ref={refInput}
        type='text'
        value={model || ''}
        onChange={({ target: { value } }) => onModelChange(value === '' ? null : value)}
        placeholder='Search for an year'
      />
    </div>
  );
};

export { DateFilterAGGrid };
