import { memo } from 'react';

import { Card, Grid, Skeleton, Stack } from '@mui/joy';

const CardLoader = memo(() => {
  return (
    <Grid xs={12} sm={6} md={4} marginTop={1} height={'100%'} gap={3}>
      <Card
        sx={{
          padding: 0,
          height: '100%',
          cursor: 'pointer',
          borderRadius: 'none',
          borderBottomRightRadius: '8px',
          borderBottomLeftRadius: '8px',
        }}
      >
        <Stack gap={2}>
          <Skeleton variant='rectangular' width={'100%'} height={'215px'} />
          <Stack p={3} gap={3}>
            <Stack gap={1}>
              <Stack direction={'row'} gap={2}>
                <Skeleton
                  variant='rectangular'
                  width={'75px'}
                  height={'24px'}
                  sx={{
                    borderRadius: '3rem',
                  }}
                />
              </Stack>
              <Stack width={'100%'} gap={1}>
                <Skeleton variant='rectangular' width={'100%'} height={'28px'} />
                <Skeleton variant='rectangular' width={'100%'} height={'28px'} />
                <Skeleton variant='rectangular' width={'100%'} height={'14px'} />
              </Stack>
            </Stack>
            <Stack alignItems={'flex-end'}>
              <Skeleton variant='rectangular' width={'100px'} height={'14px'} />
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );
});

export default CardLoader;
