import { Box } from '@mui/joy';

import { Navbar } from './Navbar';

interface PropTypes {
  children: React.ReactNode;
}
const Layout = ({ children }: PropTypes) => {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          position: 'fixed',
          top: '62px',
          mx: 'auto',
          width: 1,
          height: 'calc(100vh - 62px)',
          overflowY: 'scroll',
        }}
      >
        {children}
      </Box>
    </>
  );
};

export { Layout };
