import React from 'react';

import {
  DialogTitle,
  List,
  ListItem,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from '@mui/joy';

type Props = {
  open: boolean;
  onClose: () => void;
};
const Terms: React.FC<Props> = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        aria-labelledby='terms-and-conditions-modal'
        sx={{
          maxWidth: 800,
          maxHeight: '90vh',
        }}
      >
        <ModalClose />
        <DialogTitle>Terms and Conditions</DialogTitle>
        <Stack sx={{ gap: 1, overflowY: 'auto' }}>
          <Typography level='body-md'>
            In these Terms of Business, the following terms shall bear the meanings set out below
          </Typography>
          <Typography level='body-md'>
            <strong>"API"</strong> means the Company's application programming interface that allows
            the Customer to access the Calyx Global Data , and any API documentation, API key or
            other API-related materials or services made available by the Company to the Customer.
          </Typography>
          <Typography level='body-md'>
            <strong>"Calyx Global Data"</strong> means any component or element of data which is
            part of or derived from the Calyx Global Platform and is accessed by a Customer via the
            app or API in connection with a Calyx Global Service;
          </Typography>
          <Typography level='body-md'>
            <strong>"Calyx Global Platform"</strong> shall mean the universe of data, information,
            statistics and analysis, and their preparatory materials, with respect to carbon
            projects issuing carbon credits, including without limitation
          </Typography>
          <List marker='disc'>
            <ListItem>Regional characteristics and types of such projects;</ListItem>
            <ListItem>
              Methodologies for the assessment of such carbon projects' efficacy with regard to
              greenhouse gas removal or reduction, delivering on claims of impact on the UN
              Sustainable Development Goals or other performance dimensions;
            </ListItem>
            <ListItem>
              Governance and performance of programs issuing credits on behalf of Carbon projects
            </ListItem>
          </List>
          <Typography level='body-md'>
            which the Company makes available to or is preparing for any Customer(s) whether such
            materials are or will be communicated to the Customer by access to the Company app or
            the Company API or any other physical or virtual means; and the Company's web domain,
            API and app by means of which Calyx Global Data are hosted or made accessible.
          </Typography>
          <Typography level='body-md'>
            <strong>"Calyx Global Service"</strong> means any service which the Company provides to
            a Customer, whether payable or not, pursuant to a Subscription ;
          </Typography>
          <Typography level='body-md'>
            <strong>"Party"</strong> means the Company or the Customer as the context requires and{' '}
            <strong>"Parties"</strong> shall mean both of them;
          </Typography>
          <Typography level='body-md'>
            <strong>"Subscription"</strong> means an agreement between Company and Customer pursuant
            to which Company supplies any Calyx Global Data and/or provides one or more Calyx Global
            Service to the Customer, whether for payment or otherwise, the terms of which agreement
            incorporate these Terms of Business by reference.
          </Typography>

          <Typography level='h3' component='h2' sx={{ mt: 3, mb: 1 }}>
            License terms
          </Typography>
          <Typography level='body-md'>
            The Company grants to the Customer a non-exclusive, non-sub-licensable and
            non-transferable license for the Subscription term to access and use Calyx Global Data
            exclusively for the Customer's internal business purposes. The Customer shall not
          </Typography>
          <List marker='lower-roman'>
            <ListItem>
              rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or
              otherwise make available the Calyx Global Data to a third party, or provide
              information about the Calyx Global Data to a third party which enables it to make
              inferences about such data;
            </ListItem>
            <ListItem>
              remove any proprietary notices included within the Calyx Global Data;
            </ListItem>
            <ListItem>
              use the Calyx Global Data in any manner or for any purpose that infringes,
              misappropriates, or otherwise violates any intellectual property right or other right
              of any person, or that violates any applicable law;
            </ListItem>
            <ListItem>
              use or disclose Calyx Global Data in connection with services to third parties
              including the selection or marketing of, or advice with respect to, projects; or
            </ListItem>
            <ListItem>
              use or access, or allow any third party to use or access, Calyx Global Data as a
              resource or component of any artificial intelligence tools or services, including
              so-called generative artificial intelligence that can create text, images, video,
              audio, code, or other data based on training data.
            </ListItem>
          </List>
          <Typography level='body-md'>
            For the purpose of this clause, third party includes an entity that is affiliated to or
            associated with the Customer, or the officers, employees, contractors and agents of such
            entity.
          </Typography>

          <Typography level='h3' component='h2' sx={{ mt: 3, mb: 1 }}>
            Intellectual property
          </Typography>
          <Typography level='body-md'>
            As between the Customer and the Company, the Company owns all rights, title and
            interest, including all the intellectual property rights with respect to the Calyx
            Global Platform, including any Calyx Global Data. These materials are protected by
            copyright laws and treaties around the world. All such rights are reserved. The Calyx
            Global Platform, including any Calyx Global Data is an original compilation protected by
            intellectual property laws and the Company has dedicated substantial resources to
            collect, manage, and compile such data, and that such organization and compilation is
            proprietary to the Company.
          </Typography>

          <Typography level='h3' component='h2' sx={{ mt: 3, mb: 1 }}>
            Suitability of Calyx Global Services
          </Typography>
          <Typography level='body-md'>
            The Customer warrants that it has satisfied itself that the information provided by any
            Calyx Global Service meets its requirements and that the Company is under no obligation
            to provide or obtain for the Customer any additional information.
          </Typography>
          <Typography level='body-md'>
            The ratings and insights comprised in the Calyx Global Data are the result of applying
            the Company's proprietary frameworks to publicly available and/or acquirable information
            from third party sources. which the Company has limited opportunity to verify . The
            Company accepts no liability to the Customer for any loss, expense, liability or claim
            that the Customer may incur in connection with any rating assessment which is based upon
            erroneous data. Human judgment is involved in the application of the Company's
            frameworks; this may result in differing rating outcomes.
          </Typography>
          <Typography level='body-md'>
            The information provided by any Calyx Global Service does not constitute any form of
            advice or recommendation with respect to the relevant carbon project. A Company
            assessment rating does not indicate that a particular carbon project is suitable for
            procurement or investment by the Customer or any other person and does not provide any
            forecast whatsoever as to a carbon project's future performance. The Company does not
            accept any liability whatsoever for any loss, expense, cost or liability, howsoever
            arising from the Customer's decision to procure credits from, participate in, invest in
            or in any manner take financial exposure with respect to any carbon project on which the
            Company has provided information.
          </Typography>

          <Typography level='h3' component='h2' sx={{ mt: 3, mb: 1 }}>
            Availability of Calyx Global Services
          </Typography>
          <Typography level='body-md'>
            The Company shall use reasonable endeavors to ensure that the Calyx Global Services are
            continuously accessible for the Subscription term , but reserves the right to suspend
            availability during periods of website or app maintenance which the Company shall notify
            to Customers using the app with as much notice as reasonably possible. The Customer
            acknowledges that the Calyx Global Platform relies upon Internet resources over which
            neither Party has control, and therefore the Company does not guarantee access at all
            times . The Customer is responsible for making all arrangements necessary for the
            Customer to have access to Calyx Global Services. Company takes reasonable steps to
            secure its data, software and hardware used to manage and transmit that data from
            cyber-attacks, malicious hacking and the introduction by any means of any unauthorized
            intrusive code or programming into data processing equipment, media, software, programs,
            systems or records. Company uses industry-standard antivirus software to screen any data
            file before it is transmitted to Customer. However, Company does not guarantee that the
            data provided is free of all such unauthorized intrusive code or programming and does
            not accept liability with respect to any loss, liability, expense or claim which the
            Customer incurs by reason of the contamination of such data by such unauthorized
            intrusive code or programming.
          </Typography>

          <Typography level='h3' component='h2' sx={{ mt: 3, mb: 1 }}>
            Updates and Changes to Company Terms of Business
          </Typography>
          <Typography level='body-md'>
            The Company is under no obligation to update the content of any Calyx Global Data, but
            reserves the right to do so, in response to new information or data. The Company accepts
            no liability to the Customer for any loss, liability expense or claim incurred by the
            Customer in connection with any variation between the Calyx Global Data provided to the
            Customer and its updated version. The Company may reorder, modify or re-organise the
            presentation or structure of its Customer-facing internet resources at any time.
          </Typography>
          <Typography level='body-md'>
            The Company may amend these Terms of Business at any time by sending an email notice to
            the Customer at its contact email address; any such amendments will be prospectively
            binding on the Parties in relation to any Subscription in force effective ten (10) days
            from the date of such e mail.
          </Typography>

          <Typography level='h3' component='h2' sx={{ mt: 3, mb: 1 }}>
            Confidentiality & Customer Data
          </Typography>
          <Typography level='body-md'>
            Subject to the License terms set out above, the Parties agree not to disclose to any
            third party the existence and terms of the Subscription and the Parties' respective
            commercial information exchanged in the performance of the Subscription, save to the
            extent that such disclosure is strictly required by applicable law in which case the
            disclosing Party shall notify the other Party as soon as possible prior to making the
            disclosure. The Customer consents to the Company using tracking cookies to obtain, store
            and use information relating to the Customer's use of Calyx Global Services. Subject to
            such exception, the Company does not obtain, use or store in any way Customer's
            commercial information and accepts no responsibility for such.
          </Typography>

          <Typography level='h3' component='h2' sx={{ mt: 3, mb: 1 }}>
            Personal data
          </Typography>
          <Typography level='body-md'>
            The Company retains and processes personal data relating to individuals representing its
            Customers, but only to the extent strictly necessary to facilitate communications with
            the Customer, in accordance with the Company's Privacy Policy available at this webpage
            https://www.calyxglobal.com/privacy.
          </Typography>

          <Typography level='h3' component='h2' sx={{ mt: 3, mb: 1 }}>
            Customer Responsibilities
          </Typography>
          <Typography level='body-md'>
            The Customer is responsible for ensuring that all persons who access the Calyx Global
            Data subject to the Subscription comply with these terms. The Customer, its officers and
            employees must not damage, interfere with or disrupt access to the Calyx Global
            Platform, or do anything which might interrupt or impair its functionality.
          </Typography>

          <Typography level='h3' component='h2' sx={{ mt: 3, mb: 1 }}>
            DISCLAIMER OF WARRANTIES.
          </Typography>
          <Typography level='body-md' sx={{ textTransform: 'uppercase' }}>
            ALL CALYX GLOBAL DATA ARE PROVIDED "AS IS" AND THE COMPANY HEREBY DISCLAIMS ALL
            WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE IN RESPECT THEREOF. THE
            COMPANY SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE
            OF DEALING, USAGE, OR TRADE PRACTICE. THE COMPANY MAKES NO WARRANTY OF ANY KIND THAT
            CALYX GLOBAL SERVICES, CALYX GLOBAL DATA OR RESULTS OF ITS USE, WILL MEET THE CUSTOMER'S
            OR ANY OTHER PERSON'S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED
            RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM, OR OTHER SERVICES, OR BE
            SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE. ANY ACTION THAT
            CUSTOMER TAKES BASED ON THE SERVICES OR PRODUCTS, DATA OR RESULTS THEREFROM IS SOLELY
            THE CUSTOMER'S RESPONSIBILITY, AND THE COMPANY SHALL NOT BE RESPONSIBLE OR LIABLE FOR
            SUCH ANY ACTIONS.
          </Typography>

          <Typography level='h3' component='h2' sx={{ mt: 3, mb: 1 }}>
            LIMITATION OF LIABILITY
          </Typography>
          <Typography level='body-md' sx={{ textTransform: 'uppercase' }}>
            THE LIABILITY OF A PARTY WITH RESPECT TO ANY AND ALL SUITS, ACTIONS, LEGAL PROCEEDINGS,
            CLAIMS, DEMANDS, DAMAGES, COSTS AND EXPENSES ARISING OUT OF ITS PERFORMANCE OR
            NON-PERFORMANCE OF ANY OBLIGATIONS UNDER THIS AGREEMENT, WHETHER BASED ON CONTRACT,
            WARRANTY, TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE), STRICT LIABILITY OR BREACH
            OF DUTY, STATUTORY OR OTHERWISE, WILL BE LIMITED TO (A) DIRECT, ACTUAL DAMAGES INCURRED
            AS A RESULT OF THE PARTY'S FAILURE TO PERFORM ITS OBLIGATIONS AS REQUIRED BY THE
            SUBSCRIPTION , AND (B) WILL NOT EXCEED IN THE AGGREGATE THE PRICE FOR THE SUBSCRIPTION
            IN FORCE WHEN THE RELEVANT LIABILTY AROSE NEITHER PARTY SHALL BE LIABLE TO THE OTHER
            PARTY OR TO ANY OTHER PERSON OR ENTITY WITH RESPECT TO ANY SUBJECT MATTER OF THIS
            AGREEMENT, FOR ANY (A) INCIDENTAL, SPECIAL, CONSEQUENTIAL OR INDIRECT DAMAGES, OR (B)
            DAMAGES RESULTING FROM LOSS OF SALE, BUSINESS, PROFITS, OPPORTUNITY OR GOODWILL.
          </Typography>

          <Typography level='h3' component='h2' sx={{ mt: 3, mb: 1 }}>
            Termination
          </Typography>
          <Typography level='body-md'>
            The Company reserves the right to terminate the Subscription forthwith in the event that
          </Typography>
          <List marker='lower-roman'>
            <ListItem>
              the Customer is in breach of the Subscription terms (including without limitation
              failure to pay any Company's invoice timely); and
            </ListItem>
            <ListItem>
              the Company has sent a notice to the Customer identifying the breach and requiring it
              to be remedied within 7 days of the date of such notice; and
            </ListItem>
            <ListItem>the Customer has failed to remedy such breach within such period.</ListItem>
          </List>
          <Typography level='body-md'>
            In the event of such termination, the Customer shall not be entitled to any refund of
            payments made under the Subscription.
          </Typography>

          <Typography level='h3' component='h2' sx={{ mt: 3, mb: 1 }}>
            Cancelation
          </Typography>
          <Typography level='body-md'>
            The Subscription may not be terminated by the Customer save in the event of Company's
            material breach of the Subscription terms and any payment made under the Subscription is
            not refundable at the request of the Customer.
          </Typography>

          <Typography level='h3' component='h2' sx={{ mt: 3, mb: 1 }}>
            Effect of Expiration or Termination.
          </Typography>
          <Typography level='body-md'>
            Upon the expiry of the Subscription or its earlier termination , the license(s) granted
            by the Subscription will also terminate, and the Customer shall (a) cease using Calyx
            Global Data; (b) delete the Calyx Global Data from all devices and systems the Customer
            directly or indirectly controls; and (c) certify in writing to the Company that the
            Calyx Global Data have been deleted provided always that the Customer may retain copies
            of Calyx Global Data which it has used during the Subscription term for internal
            business purposes to the extent required to meet applicable laws, accounting and
            corporate governance procedures. This paragraph shall survive and continue to bind the
            Parties notwithstanding the termination of the Subscription.
          </Typography>
          <Typography level='h3' component='h2' sx={{ mt: 3, mb: 1 }}>
            Governing law
          </Typography>
          <Typography level='body-md'>
            The Subscription is governed by the laws of the State of New York save for any conflict
            of laws provisions which would otherwise provide for a different law to apply.
          </Typography>
          <Typography level='h3' component='h2' sx={{ mt: 3, mb: 1 }}>
            Jurisdiction
          </Typography>
          <Typography level='body-md'>
            To the maximum extent permitted by law, any dispute, controversy or claim arising out of
            or relating to the Subscription, including the formation, interpretation, breach or
            termination thereof, including whether the claims asserted are arbitrable, will be
            referred to and finally determined by arbitration in accordance with the JAMS
            International Arbitration Rules. The tribunal will consist of three arbitrators. Within
            15 days after the commencement of arbitration, each Party shall select one person to act
            as arbitrator, and the two so selected shall select a third arbitrator within 30 days of
            the commencement of the arbitration. If the arbitrators selected by the Parties are
            unable or fail to agree upon the third arbitrator within the allotted time, the third
            arbitrator shall be appointed by JAMS in accordance with its rules. All arbitrators
            shall serve as neutral, independent and impartial arbitrators. The seat of the
            arbitration will be Denver, CO. The language to be used in the arbitral proceedings will
            be English. Judgment upon the award rendered by the arbitrator(s) may be entered by any
            court having jurisdiction thereof. The Parties have agreed to submit to the exclusive
            jurisdiction of the federal and state courts of New York with respect to any matter
            which is prohibited from resolution by arbitration in accordance with this clause by
            reason of applicable law. Each Party hereby waives, to the maximum extent permitted by
            law, any objection, including any objection based on forum non conveniens, to the
            bringing of any such proceeding in such jurisdictions. In respect of any proceedings
            before the state courts of New York, the Parties each waive any right to jury trial.
          </Typography>

          <Typography level='h3' component='h2' sx={{ mt: 3, mb: 1 }}>
            Other terms
          </Typography>
          <Typography level='body-md'>
            If any provision of the Subscription is, or becomes, unenforceable, illegal or invalid
            for any reason, the relevant provision is deemed to be varied to the extent necessary to
            remedy the unenforceability, illegality or invalidity. If variation is not possible, the
            provision must be treated as severed from the Subscription without affecting any other
            provisions of the Subscription. To waive a right under the Subscription, the waiver must
            be in writing and signed by the waiving Party. Any variation to the Subscription must be
            in writing and signed by both Parties. Unless expressly set out in the Subscription, no
            person which is not a party to the Subscription shall acquire any right, benefit or
            obligation hereunder. The Subscription is personal to the Customer and may not be
            assigned to a Third party, including any affiliate or associated entity of the Customer
            without the prior written consent of the Company. The paragraphs headed “Confidentiality
            & Customer Data”, “Intellectual Property”, “Limitation of Liability”, “Disclaimer of
            Warranties”, “Suitability of Calyx Global Services”, “Updates and Changes to Company
            Terms of Business”, “Governing Law”, “Jurisdiction” and “Other terms” of these Terms of
            Business shall survive and continue to bind the Parties notwithstanding the termination
            of the Subscription.
          </Typography>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};

export { Terms };
