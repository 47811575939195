import React from 'react';

import { AuthProvider } from '@propelauth/react';
import { PostHogProvider } from 'posthog-js/react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import posthog from './services/analytics/posthog';
import 'reset-css';
import './services/sentry';

function loadLocaleData(locale: string) {
  switch (locale) {
    default:
      return import('../lang/compiled/en.json');
  }
}

(async () => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  let messages: any = null;

  if (process.env.NODE_ENV === 'production') {
    messages = await loadLocaleData('en');
  }

  root.render(
    <React.StrictMode>
      <AuthProvider authUrl={process.env.REACT_APP_AUTH_URL!}>
        <PostHogProvider client={posthog}>
          <App messages={messages} />
        </PostHogProvider>
      </AuthProvider>
    </React.StrictMode>
  );

  reportWebVitals();
})();
