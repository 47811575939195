import { Box, Chip, Stack, Typography } from '@mui/joy';
import { SideBarDef } from 'ag-grid-community';
import { ISetFilterCellRendererParams, SetFilterValuesFuncParams } from 'ag-grid-enterprise';

import Image from '../../components/Image';
import { RatingCircle } from '../../components/RatingCircle';
import { assetBaseUrl } from '../../services/axios/endpoints';
import { dark } from '../../theme/dark';
import { light } from '../../theme/light';
import { dateFormat } from '../../utils/functions/date';
import { getProjectAttributes } from '../../utils/functions/other';

import { DateFilterAGGrid } from './components/DateFilterAGGrid';
import { AG_GRID_GLOBAL_SEARCH_INPUT_ID } from './Search';

export const agGridDataTransformer = (data: any, filters: any) => {
  return {
    data: data
      ?.map((project: any) => {
        return project?.project_crediting_periods?.map((creditingPeriod: any) => {
          const projectAttributes = getProjectAttributes(
            creditingPeriod?.project_attributes
          ).attributeNames;
          let projectCountry = project?.locations?.[0]?.location?.parent?.display_name;
          let projectRegion = project?.locations?.[0]?.location?.parent?.parent?.display_name;
          const locationData = project?.locations?.[0]?.location;
          if (locationData.type === 'state') {
            projectCountry = locationData.parent?.display_name || '';
            projectRegion = locationData.parent?.parent?.display_name || '';
          } else if (locationData.type === 'country') {
            projectCountry = locationData.display_name || '';
            projectRegion = locationData.parent?.display_name || '';
          } else if (locationData.type === 'region') {
            projectCountry = locationData.display_name || '';
            projectRegion = '';
          }
          let startDuration: number | null = null;
          let endDuration: number | null = null;
          if (!!creditingPeriod?.start_date && !!creditingPeriod?.end_date) {
            startDuration = dateFormat(creditingPeriod?.start_date).utcYear;
            endDuration = dateFormat(creditingPeriod?.end_date).utcYear;
          } else {
            startDuration = dateFormat(project?.start_year).utcYear;
            endDuration = dateFormat(project?.end_year).utcYear;
          }
          const projectType = project?.project_types[0]?.project_type?.display_name;
          const projectGroup = project?.project_types[0]?.project_type?.project_group?.display_name;
          const projectGroupName = project?.project_types[0]?.project_type?.project_group?.name;
          const creditingPeriodTitle = creditingPeriod?.title;
          const creditingPeriodId = creditingPeriod?.crediting_period_id;
          const logo = project?.project_types[0]?.project_type?.logo;
          const parentProject = {
            type: project?.parent?.ghg_standard?.code,
            id: project?.parent?.project_id,
            name: project?.parent?.name,
          };
          return {
            logo,
            project: project?.name,
            creditingPeriodTitle,
            creditingPeriodId,
            projectId: project?.project_id,
            projectNameHierarchy: [
              project?.name,
              `${project?.ghg_standard?.code}${project?.project_id}`,
            ],
            ghgStandardCode: project?.ghg_standard?.code,
            projectType,
            projectGroup,
            projectGroupName,
            projectTypesHierarchy: [projectGroup, projectType],
            location: {
              country: projectCountry,
              region: projectRegion,
            },
            projectRegion,
            projectCountry,
            locationsHierarchy: [projectRegion, projectCountry],
            duration: {
              start: startDuration,
              end: endDuration,
            },
            ghgRating: creditingPeriod?.ratings?.ghg_rating_detail?.grade,
            ghgRatingNumber: creditingPeriod?.ratings?.ghg_rating_detail?.rating,
            ghgRatingPercentage: creditingPeriod?.ratings?.ghg_rating_detail?.percentage,
            sdgRating: creditingPeriod?.ratings?.sdg_rating_detail?.grade,
            sdgRatingNumber: creditingPeriod?.ratings?.sdg_rating_detail?.rating,
            sdgRatingPercentage: creditingPeriod?.ratings?.sdg_rating_detail?.percentage,
            confirmedSDGs: creditingPeriod?.ratings?.confirmed_sdgs,
            sdgCertificate: creditingPeriod?.ratings?.sdg_certificate?.code,
            projectAttributes,
            isFollowedByCurrentUser: Boolean(creditingPeriod?.project_follow_user?.[0]?.status),
            parentProject,
          };
        });
      })
      .flat(),
    filters,
  };
};

export const getSDGRatingColor = (sdgRating: string, isDarkMode: boolean) => {
  return (
    {
      '+5': isDarkMode ? dark.SDG[5] : light.SDG[5],
      '+4': isDarkMode ? dark.SDG[4] : light.SDG[4],
      '+3': isDarkMode ? dark.SDG[3] : light.SDG[3],
      '+2': isDarkMode ? dark.SDG[2] : light.SDG[2],
      '+1': isDarkMode ? dark.SDG[1] : light.SDG[1],
    }?.[sdgRating] ?? null
  );
};

export const getSDGRatingColorV2 = (sdgRating: string, isDarkMode: boolean) => {
  return (
    {
      '+5': isDarkMode ? dark.SDGV2[5] : light.SDGV2[5],
      '+4': isDarkMode ? dark.SDGV2[4] : light.SDGV2[4],
      '+3': isDarkMode ? dark.SDGV2[3] : light.SDGV2[3],
      '+2': isDarkMode ? dark.SDGV2[2] : light.SDGV2[2],
      '+1': isDarkMode ? dark.SDGV2[1] : light.SDGV2[1],
      'No cert': isDarkMode ? dark.SDGV2['No cert'] : light.SDGV2['No cert'],
    }?.[sdgRating] ?? null
  );
};

export const getGHGratingColor = (ghgRating: string, isDarkMode: boolean) => {
  return (
    {
      'A+': isDarkMode ? dark.GHG['A+'] : light.GHG['A+'],
      A: isDarkMode ? dark.GHG['A'] : light.GHG['A'],
      'B+': isDarkMode ? dark.GHG['B+'] : light.GHG['B+'],
      B: isDarkMode ? dark.GHG['B'] : light.GHG['B'],
      'C+': isDarkMode ? dark.GHG['C+'] : light.GHG['C+'],
      C: isDarkMode ? dark.GHG['C'] : light.GHG['C'],
      'D+': isDarkMode ? dark.GHG['D+'] : light.GHG['D+'],
      D: isDarkMode ? dark.GHG['D'] : light.GHG['D'],
      'E+': isDarkMode ? dark.GHG['E+'] : light.GHG['E+'],
      E: isDarkMode ? dark.GHG['E'] : light.GHG['E'],
    }?.[ghgRating] ?? null
  );
};

export const getGHGratingColorV2 = (ghgRating: string, isDarkMode: boolean) => {
  return (
    {
      'A+': isDarkMode ? dark.GHGV2['A+'] : light.GHGV2['A+'],
      A: isDarkMode ? dark.GHGV2['A'] : light.GHGV2['A'],
      'B+': isDarkMode ? dark.GHGV2['B+'] : light.GHGV2['B+'],
      B: isDarkMode ? dark.GHGV2['B'] : light.GHGV2['B'],
      'C+': isDarkMode ? dark.GHGV2['C+'] : light.GHGV2['C+'],
      C: isDarkMode ? dark.GHGV2['C'] : light.GHGV2['C'],
      'D+': isDarkMode ? dark.GHGV2['D+'] : light.GHGV2['D+'],
      D: isDarkMode ? dark.GHGV2['D'] : light.GHGV2['D'],
      'E+': isDarkMode ? dark.GHGV2['E+'] : light.GHGV2['E+'],
      E: isDarkMode ? dark.GHGV2['E'] : light.GHGV2['E'],
    }?.[ghgRating] ?? null
  );
};

export const getSDGColor = (sdg: number) => {
  if (sdg < 1 || sdg > 17) return '';
  return [
    '#E5243B',
    '#DDA83A',
    '#4C9F38',
    '#C5192D',
    '#FF3A21',
    '#26BDE2',
    '#FCC30B',
    '#A21942',
    '#FD6925',
    '#DD1367',
    '#FD9D24',
    '#BF8B2E',
    '#3F7E44',
    '#0A97D9',
    '#56C02B',
    '#00689D',
    '#19486A',
  ][sdg - 1];
};
export const sdgRatingsArray = ['+5', '+4', '+3', '+2', '+1'];
export const ghgRatingsArray = ['A+', 'A', 'B+', 'B', 'C+', 'C', 'D+', 'D', 'E+', 'E'];
export const getGHGGradeFromRatingNumber = (sdgRating: number) => {
  if (sdgRating < 1 || sdgRating > 11) return;
  return ghgRatingsArray[sdgRating - 1];
};

export const getProjectGroupDetails = (data) => {
  if (!data) return { groups: [], details: {} };
  const details = {};
  data.map(({ projectGroup, projectGroupName }) => {
    if (projectGroupName in details) return;
    details[projectGroupName] = projectGroup;
  });
  const orderedDetails = Object.keys(details)
    .sort()
    .reduce((obj, key) => {
      obj[key] = details[key];
      return obj;
    }, {});
  return {
    groups: Object.keys(orderedDetails),
    details,
  };
};

export const agGridColumnDefs: any[] = [
  {
    field: 'projectGroup',
    headerName: 'Project Category',
    hide: true,
    filter: true,
  },
  {
    field: 'parentProject',
    headerName: 'Parent Project',
    hide: true,
    suppressToolPanel: true,
    getQuickFilterText: (params: any) => {
      return Object.values(params.value || {}).join('');
    },
  },
  {
    field: 'projectType',
    headerName: 'Project Type',
    hide: true,
    filter: true,
  },
  {
    field: 'projectRegion',
    headerName: 'Region',
    filter: true,
    hide: true,
  },
  {
    field: 'projectCountry',
    headerName: 'Country',
    filter: true,
    hide: true,
  },
  {
    field: 'projectNameHierarchy',
    minWidth: 400,
    pinned: 'left',
    headerName: 'Project',
    suppressHeaderMenuButton: true,
    suppressColumnsToolPanel: true,
    filter: false,
    autoHeight: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
    },
    getQuickFilterText: (params: any) => {
      return params?.value?.join ? params?.value?.join('') : params?.value;
    },
    cellRenderer: (params: any) => {
      const project = params?.data?.project;
      const ghgStandardCode = params?.data?.ghgStandardCode;
      const projectID = params?.data?.projectId;
      return (
        <Stack justifyContent={'center'} spacing={0.5} height={'100%'}>
          <Typography fontSize={'md'} whiteSpace={'wrap'} color='primary'>
            {project}
          </Typography>
          <Typography fontSize={'sm'}>
            {ghgStandardCode}
            {projectID}
          </Typography>
        </Stack>
      );
    },
  },
  {
    field: 'projectTypesHierarchy',
    headerName: 'Type',
    suppressHeaderMenuButton: true,
    minWidth: 310,
    filter: true,
    autoHeight: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
    },
    suppressFiltersToolPanel: true,
    cellRenderer: (params: any) => {
      const type = params?.data?.projectType;
      const group = params?.data?.projectGroup;
      const logo = params?.data?.logo;
      return (
        <Stack direction={'row'} alignItems={'center'}>
          <Box
            sx={{
              width: '45px',
              objectFit: 'contain',
              display: 'flex',
              alignItems: 'center',
              mr: 2,
            }}
          >
            {!!logo ? (
              <Image
                src={`${assetBaseUrl}/${logo}`}
                style={{ width: '36px', height: '100%', objectFit: 'contain' }}
                alt=''
              />
            ) : (
              <Box width={'36px'} />
            )}
          </Box>
          <Stack justifyContent={'center'} spacing={0.5} height={'100%'} width={'100%'}>
            <Typography color='primary' fontSize={'md'} whiteSpace={'wrap'}>
              {type}
            </Typography>
            <Typography
              fontSize={'sm'}
              sx={(theme) => {
                return { color: theme.palette.text.secondary };
              }}
            >
              {group}
            </Typography>
          </Stack>
        </Stack>
      );
    },
  },
  {
    field: 'locationsHierarchy',
    headerName: 'Location',
    suppressHeaderMenuButton: true,
    filter: true,
    autoHeight: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
    },
    filterParams: {
      treeList: true,
      values: (params: SetFilterValuesFuncParams) => {
        const filterValues = new Set<string>([]);
        params.api.forEachNodeAfterFilter(
          (node) =>
            node.data?.locationsHierarchy?.length > 0 &&
            node.data?.locationsHierarchy?.forEach((location: string) => {
              if (!!location) {
                filterValues.add(location);
              }
            })
        );
        params.success(Array.from(filterValues));
      },
    },
    suppressFiltersToolPanel: true,
    cellRenderer: (params: any) => {
      const region = params?.data?.location?.region;
      const country = params?.data?.location?.country;
      return (
        <Stack justifyContent={'center'} spacing={0.5} height={'100%'}>
          <Typography color='primary' fontSize={'md'} whiteSpace={'wrap'}>
            {country}
          </Typography>
          <Typography
            fontSize={'sm'}
            sx={(theme) => {
              return { color: theme.palette.text.secondary };
            }}
          >
            {region}
          </Typography>
        </Stack>
      );
    },
  },
  {
    field: 'duration',
    headerName: 'Duration / Vintage',
    suppressHeaderMenuButton: true,
    sortable: false,
    autoHeight: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
    },
    filter: DateFilterAGGrid,
    minWidth: 220,
    getQuickFilterText: (params: any) => {
      const startYear = params?.value?.start;
      const endYear = params?.value?.end;
      const globalSearchInputText = Number(
        (document.getElementById(AG_GRID_GLOBAL_SEARCH_INPUT_ID) as HTMLInputElement)?.value
      );
      if (globalSearchInputText >= startYear && globalSearchInputText <= endYear) {
        return globalSearchInputText;
      }
      return '';
    },
    cellRenderer: (params: any) => {
      const start = params?.data?.duration?.start;
      const end = params?.data?.duration?.end;
      const creditingPeriodTitle = params?.data?.creditingPeriodTitle;
      return (
        <Stack justifyContent={'center'} spacing={0.5} height={'100%'}>
          <Typography color='primary' fontSize={'md'}>
            {!!start && !!end ? `${start} - ${end}` : '-'}
          </Typography>
          <Typography
            fontSize={'sm'}
            sx={(theme) => {
              return { color: theme.palette.text.secondary };
            }}
          >
            {creditingPeriodTitle}
          </Typography>
        </Stack>
      );
    },
  },
  {
    field: 'ghgRatingNumber',
    headerName: 'GHG Rating',
    suppressHeaderMenuButton: true,
    sortable: true,
    filter: true,
    autoHeight: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    filterParams: {
      values: (params: SetFilterValuesFuncParams) => {
        const filterValues = new Set<string>([]);
        params.api.forEachNodeAfterFilter(
          (node) => !!node.data?.ghgRatingNumber && filterValues.add(node.data?.ghgRatingNumber)
        );
        params.success(Array.from(filterValues));
      },
      cellRenderer: (params: any) => {
        const value = params?.value;
        if (value === '(Select All)') {
          return params.valueFormatted;
        }
        const ghgGrade = getGHGGradeFromRatingNumber(value);
        if (!!ghgGrade) {
          return getGHGGradeFromRatingNumber(value);
        }
      },
    },
    sort: 'asc',
    width: 120,
    comparator: function (valueA: number, valueB: number) {
      if (valueA === 0) return 1;
      if (valueB === 0) return -1;
      return valueA - valueB;
    },
    cellRenderer: (params: any) => {
      const rating = params?.data?.ghgRating;
      const percentage = params?.data?.ghgRatingPercentage;
      return (
        <Stack
          display={'flex'}
          justifyContent={'center'}
          spacing={0.5}
          height={'100%'}
          alignItems={'center'}
          width={'70px'}
        >
          <RatingCircle type='ghg' percentage={percentage} rating={rating} size='sm' />
        </Stack>
      );
    },
  },
  {
    field: 'sdgRatingNumber',
    headerName: 'SDG Rating',
    suppressHeaderMenuButton: true,
    sortable: true,
    filter: true,
    autoHeight: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    filterParams: {
      values: (params: SetFilterValuesFuncParams) => {
        const filterValues = new Set<string>([]);
        params.api.forEachNodeAfterFilter((node) => filterValues.add(node.data?.sdgRatingNumber));
        params.success(Array.from(filterValues));
      },
      cellRenderer: function (params: any) {
        const value = params?.value;
        if (value === '(Select All)') {
          return params.valueFormatted;
        }
        const sdgData = value;
        return sdgData === 0 ? 'No Cert' : sdgData > 0 ? '+' + sdgData : 'N/A';
      },
    },
    width: 120,
    cellRenderer: (params: any) => {
      const rating = params?.data?.sdgRating;
      const percentage = params?.data?.sdgRatingPercentage;
      return (
        <Stack
          display={'flex'}
          justifyContent={'center'}
          spacing={0.5}
          height={'100%'}
          alignItems={'center'}
          width={'70px'}
        >
          <RatingCircle type='sdg' percentage={percentage} rating={rating} size='sm' />
        </Stack>
      );
    },
  },
  {
    field: 'confirmedSDGs',
    headerName: 'Confirmed SDGs',
    suppressHeaderMenuButton: true,
    sortable: true,
    autoHeight: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    filter: true,
    width: 180,
    filterParams: {
      suppressSelectAll: true,
      defaultToNothingSelected: true,
      comparator: (a: number, b: number) => {
        if (a === b) return 0;
        return a > b ? 1 : -1;
      },
      values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
      cellRenderer: (params: ISetFilterCellRendererParams) => {
        return `SDG ${params.value}`;
      },
    },
    cellRenderer: (params: any) => {
      const confirmedSDGs = params?.data?.confirmedSDGs;
      return confirmedSDGs?.length ? (
        <Stack
          justifyContent={'flex-start'}
          height={'100%'}
          alignItems={'center'}
          direction={'row'}
          flexWrap={'wrap'}
          minWidth={'150px'}
          gap={0.5}
        >
          {confirmedSDGs?.map((sdg: number) => {
            return (
              <Box
                margin={0}
                width={'24px'}
                height={'24px'}
                borderRadius={'sm'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ backgroundColor: getSDGColor(sdg), aspectRatio: '1/1' }}
              >
                <Typography
                  fontWeight={'xl'}
                  fontSize={'md'}
                  sx={(theme) => {
                    return { color: theme.palette.common.white };
                  }}
                >
                  {sdg}
                </Typography>
              </Box>
            );
          })}
        </Stack>
      ) : (
        <Stack alignItems={'center'} justifyContent={'center'} width={'100%'} height={'100%'}>
          -
        </Stack>
      );
    },
  },
  {
    field: 'sdgCertificate',
    headerName: 'SDG Certificate',
    suppressHeaderMenuButton: true,
    filter: true,
    width: 150,
    autoHeight: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    filterParams: {
      values: (params: SetFilterValuesFuncParams) => {
        const filterValues = new Set<string>([]);
        params.api.forEachNodeAfterFilter(
          (node) => !!node.data?.sdgCertificate && filterValues.add(node.data?.sdgCertificate)
        );
        params.success(Array.from(filterValues));
      },
    },
    cellRenderer: (params: any) => {
      const sdgCert = params?.data?.sdgCertificate;
      return (
        <Stack
          justifyContent={'center'}
          spacing={0.5}
          height={'100%'}
          alignItems={'center'}
          width={'100px'}
        >
          {sdgCert ? (
            <Chip variant='outlined'>{sdgCert}</Chip>
          ) : (
            <Typography fontSize={'md'}>-</Typography>
          )}
        </Stack>
      );
    },
  },
  {
    field: 'projectAttributes',
    headerName: 'Attributes',
    suppressHeaderMenuButton: true,
    filter: true,
    minWidth: 250,
    autoHeight: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
    },
    filterParams: {
      suppressSelectAll: true,
      defaultToNothingSelected: true,
      cellRenderer: (params: ISetFilterCellRendererParams) => {
        if (!params?.value) return 'None';
        return params.value;
      },
      values: (params: SetFilterValuesFuncParams) => {
        const filterValues = new Set<string>([]);
        params.api.forEachNodeAfterFilter((node) => {
          return (
            !!node.data?.projectAttributes &&
            node.data?.projectAttributes.length > 0 &&
            node.data?.projectAttributes.map((attribute: string) => filterValues.add(attribute))
          );
        });
        params.success(Array.from(filterValues));
      },
    },
    cellRenderer: (params: any) => {
      const attributes = params?.data?.projectAttributes;
      return (
        <Stack
          justifyContent={'flex-start'}
          height={'100%'}
          width={'100%'}
          direction={'row'}
          alignItems={'center'}
          flexWrap={'wrap'}
          padding={1}
          gap={1}
        >
          {attributes?.length
            ? attributes.map((attribute: string) => {
                return <Chip variant='outlined'>{attribute}</Chip>;
              })
            : '-'}
        </Stack>
      );
    },
  },
  {
    field: 'isFollowedByCurrentUser',
    minWidth: 30,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    filter: true,
    hide: true,
  },
];

export const AG_GRID_FILTER_TOOLPANEL_ID = 'filters';
export const sideBarConfig: SideBarDef = {
  toolPanels: [
    {
      id: AG_GRID_FILTER_TOOLPANEL_ID,
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      toolPanelParams: {
        suppressExpandAll: false,
        suppressFilterSearch: true,
        suppressSyncLayoutWithGrid: true,
      },
    },
  ],
};

export interface ViewTypes {
  value: string;
  label: string;
  event: string;
}
