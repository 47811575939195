import { Box } from '@mui/joy';

import { PaywallModal } from '../PaywallModal';

interface PropTypes {
  isLoadingComplete: boolean;
  showPaywall: boolean;
  headingText: string;
  bodyText: string;
}

export default function Paywall({
  isLoadingComplete,
  showPaywall = true,
  bodyText,
  headingText,
}: PropTypes) {
  return showPaywall ? (
    <Box
      position={'absolute'}
      top={0}
      left={0}
      width={'100vw'}
      height={'calc(100vh - 90px)'}
      sx={{ backdropFilter: 'blur(10px)' }}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      flexDirection={'column'}
      zIndex={11}
    >
      {isLoadingComplete ? <PaywallModal headingText={headingText} bodyText={bodyText} /> : null}
    </Box>
  ) : null;
}
