import { AccordionSummary, Box, Checkbox, ListItem, styled } from '@mui/joy';
import { MdOutlineChevronRight } from 'react-icons/md';

export const AnimatedBox = styled(Box)<{ isOpen: boolean }>(({ isOpen }) => ({
  overflow: 'hidden',
  transition: 'max-height 0.5s ease',
  maxHeight: isOpen ? 'max-content' : '0px',
}));

export const AnimatedArrow = styled(MdOutlineChevronRight)<{ isOpen: boolean }>(({ isOpen }) => ({
  transition: 'transform 0.3s ease',
  transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
}));

export const StyledListItem = styled(ListItem)<{ isActive: boolean }>(({ isActive, theme }) => ({
  border: isActive ? `1px solid ${theme.palette.primary.outlinedActiveBg}` : 'none',
  color: theme.palette.text.primary,
  cursor: 'pointer',
  ':hover': {
    background: theme.palette.primary.outlinedHoverBg,
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  '.MuiAccordionSummary-button:hover': {
    background: 'transparent',
  },
  '&.Mui-expanded': {
    border: `1px solid ${theme.palette.primary.outlinedActiveBg}`,
    borderRadius: 0,
  },
}));

export const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-checked, .MuiCheckbox-indeterminate': {
    svg: {
      background: theme.palette.primary.outlinedHoverBg,
      border: `1px solid ${theme.palette.primary.outlinedActiveBg}`,
      color: theme.palette.text.icon,
      borderRadius: '4px',
    },
  },
  '&:hover .MuiCheckbox-checkbox': {
    background: theme.palette.primary.softHoverBg,
  },
}));
