import React from 'react';

import { StyledTd } from './styles';

type CellProps = React.HTMLAttributes<HTMLTableCellElement>;

const Cell: React.FC<React.PropsWithChildren<CellProps>> = ({ children, ...props }) => {
  return <StyledTd {...props}>{children}</StyledTd>;
};

export default Cell;
