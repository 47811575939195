import { useLogoutFunction } from '@propelauth/react';
import { useSetRecoilState } from 'recoil';

import { userState } from '../../../stores/user';

const useLogout = () => {
  const logoutFunction = useLogoutFunction();
  const setUserInfo = useSetRecoilState(userState);
  const onLogout = async () => {
    await logoutFunction(false);
    setUserInfo(null);
  };
  return onLogout;
};

export { useLogout };
