import { memo } from 'react';

import { Stack, Skeleton, Card, Grid } from '@mui/joy';

const ResourceDescriptionLoader = memo(() => {
  return (
    <Card sx={{ width: '100%' }}>
      <Stack gap={2} direction={'column'} width={'100%'}>
        <Stack direction={'row'} gap={1}>
          <Skeleton
            variant='rectangular'
            width={'4rem'}
            height={'24px'}
            sx={{
              borderRadius: '3rem',
            }}
          />
        </Stack>
        <Skeleton variant='rectangular' width={'100%'} height={'3rem'} />
        <Stack>
          <Skeleton variant='rectangular' width={'100%'} height={'20px'} />
        </Stack>
        <Grid container gap={'16px'} alignItems={'center'}>
          <Grid xs={12} lg={5.9}>
            <Skeleton
              variant='rectangular'
              height={'340px'}
              sx={{
                borderRadius: 'lg',
              }}
            />
          </Grid>
          <Grid xs={12} lg={5.9}>
            {Array(7)
              .fill('')
              .map(() => (
                <Skeleton variant='rectangular' width={'100%'} height={'1rem'} sx={{ my: 1 }} />
              ))}
            <Skeleton
              variant='rectangular'
              width={'12rem'}
              height={'2.5rem'}
              sx={{
                borderRadius: 'lg',
                mt: 3,
              }}
            ></Skeleton>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
});
export default ResourceDescriptionLoader;
