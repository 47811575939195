import { Button, Modal, ModalClose, Sheet, Stack, Typography } from '@mui/joy';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { makeAuthenticatedPostRequest } from '../../../services/axios';
import { registerInterestRequest } from '../../../services/axios/endpoints';
import { snackbarState } from '../../../stores/snackerbar';
import { userState } from '../../../stores/user';

interface PropTypes {
  open: boolean;
  handleClose: () => void;
  projectName: string;
  projectId: number;
}

const RegisterInterestModal = (props: PropTypes) => {
  const { open, handleClose, projectId, projectName } = props;

  const userInfo = useRecoilValue(userState);
  const setSnackbar = useSetRecoilState(snackbarState);

  const registerInterest = async () => {
    try {
      await makeAuthenticatedPostRequest(registerInterestRequest, {
        project_id: projectId,
      });
    } catch (err) {
      setSnackbar({ message: 'Something went wrong, please try again.', color: 'danger' });
    } finally {
      setSnackbar({ message: 'Successfully registered your interest!', color: 'success' });
      handleClose();
    }
  };

  return (
    <Modal
      aria-labelledby='modal-title'
      aria-describedby='modal-desc'
      open={open}
      onClose={handleClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant='outlined'
        sx={{
          maxWidth: 900,
          borderRadius: 'md',
          p: 3,
          boxShadow: 'lg',
        }}
      >
        <ModalClose variant='plain' sx={{ m: 1 }} />
        <Typography component='h2' level='h3' fontWeight='lg' mb={1}>
          Register your interest in {projectName}
        </Typography>
        <Typography marginY={2}>
          By clicking submit, your interest to purchase these credits will be registered. We will
          reach out to you via email at {userInfo?.email}.
        </Typography>
        <Typography>Do you wish to continue?</Typography>
        <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={'flex-end'}>
          <Button variant='outlined' onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={registerInterest}>Submit</Button>
        </Stack>
      </Sheet>
    </Modal>
  );
};

export { RegisterInterestModal };
