import { useMemo } from 'react';

import { useTheme } from '@mui/joy';
import EChartsReact from 'echarts-for-react';

const RetirementComparisonGraph = ({ data }: { data: any }) => {
  const theme = useTheme();
  const option = useMemo(() => {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        data: ['This project'],
        bottom: 1, // Adjust the value as needed
        left: 10, // Adjust the value as needed
        icon: 'circle', // Use circle for rounded icons
        textStyle: {
          color: theme.palette.text.primary,
          fontFamily: "'Inter Variable', sans-serif",
        },
      },
      grid: {
        top: '2%',
        // right: '0%',
        //bottom: '1%',
        // left: '0%',
        containLabel: false,
      },
      toolbox: {
        show: false,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          mark: {
            show: true,
          },
          dataView: {
            show: true,
            readOnly: false,
          },
          magicType: {
            show: true,
            type: ['line', 'bar', 'stack'],
          },
          restore: {
            show: true,
          },
          saveAsImage: {
            show: true,
          },
        },
      },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            show: false,
          },
          data: (data || []).map((value) => value[0]),
          axisLabel: {
            textStyle: {
              fontFamily: "'Inter Variable', sans-serif",
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            show: true,
            textStyle: {
              fontFamily: "'Inter Variable', sans-serif",
            },
          },
        },
      ],

      series: [
        {
          name: 'This project',
          type: 'bar',
          barGap: 0,
          emphasis: {
            focus: 'series',
          },
          barWidth: '16px',
          data: data,
          itemStyle: {
            normal: {
              barBorderRadius: [4, 4, 0, 0],
              color: '#FFCB14',
            },
          },
        },
      ],
    };
  }, [data, theme.palette.text.primary]);

  return <EChartsReact option={option} style={{ width: '100%', height: '400px' }} />;
};

export default RetirementComparisonGraph;
