import { atom } from 'recoil';

const IS_STAGING = 'isStaging';
interface AppState {
  isStaging: boolean;
}

export const appState = atom<AppState>({
  key: 'appState',
  default: {
    isStaging: localStorage.getItem(IS_STAGING) === 'true',
  },
  effects: [
    ({ onSet }) => {
      onSet((appState) => {
        localStorage.setItem(IS_STAGING, `${appState?.isStaging}`);
      });
    },
  ],
});
