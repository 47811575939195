import { useMemo } from 'react';

import { useTheme } from '@mui/joy';
import EChartsReact from 'echarts-for-react';

const AnnualCreditGraph = ({
  data,
  selectedValue,
  showForcast,
}: {
  data: any;
  selectedValue: 'activity' | 'vintage';
  showForcast: boolean;
}) => {
  const theme = useTheme();
  const option = useMemo(() => {
    const graphData = data?.[selectedValue === 'activity' ? 'activityYear' : 'vintage'] || null;
    const showForcastValue = showForcast && selectedValue === 'vintage' && !!data?.forecastValue;

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        data: ['Issued', 'Retired', 'Verified'],
        bottom: 1, // Adjust the value as needed
        left: 10, // Adjust the value as needed
        icon: 'circle', // Use circle for rounded icons
        textStyle: {
          color: theme.palette.text.primary,
          fontFamily: "'Inter Variable', sans-serif",
        },
      },
      grid: {
        top: '2%',
        containLabel: false,
      },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              fontFamily: "'Inter Variable', sans-serif",
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: 'tCO2e',
          nameLocation: 'middle',
          nameGap: 65,
          axisLabel: {
            textStyle: {
              fontFamily: "'Inter Variable', sans-serif",
            },
          },
        },
      ],
      series: [
        {
          name: 'Issued',
          type: 'bar',
          barGap: 0,
          // label: labelOption,
          emphasis: {
            focus: 'series',
          },
          barWidth: '16px',
          data: graphData?.issued || [],
          itemStyle: {
            normal: {
              barBorderRadius: [4, 4, 0, 0],

              color: '#00CDE5',
            },
          },
        },

        {
          name: 'Retired',
          type: 'bar',
          // label: labelOption,
          emphasis: {
            focus: 'series',
          },
          barWidth: '16px',
          data: graphData?.retired || [],
          itemStyle: {
            normal: {
              barBorderRadius: [4, 4, 0, 0],
              color: '#FFCB14',
            },
          },
        },
        // {
        //   name: 'Verified',
        //   type: 'bar',
        //   // label: labelOption,
        //   emphasis: {
        //     focus: 'series',
        //   },
        //   barWidth: '16px',
        //   data: data.verified : [],
        //   itemStyle: {
        //     normal: {
        //       barBorderRadius: [4, 4, 0, 0],
        //       color: '#BA51EB',
        //     },
        //   },
        // },
        {
          name: 'forecast',
          type: 'bar',
          label: {},
          emphasis: showForcastValue
            ? {
                focus: 'series',
              }
            : null,
          barWidth: '16px',
          data: [],
          itemStyle: {
            normal: {
              barBorderRadius: [4, 4, 0, 0],
              color: '#B3B3B3',
            },
          },
          markLine: showForcastValue
            ? {
                symbol: 'none',
                data: [
                  {
                    name: 'Horizontal line with Y value at 100',
                    yAxis: data?.forecastValue || 0,
                  },
                ],
                label: {
                  color: '#B3B3B3',
                  //  fontStyle: "italic",
                  formatter: 'Forecast',
                },
              }
            : null,
        },
      ],
    };
  }, [data, selectedValue, theme]);

  return (
    <EChartsReact option={option} style={{ width: '100%', height: '300px', margin: 'auto' }} />
  );
};

export default AnnualCreditGraph;
