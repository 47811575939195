import { useEffect, useMemo, useState } from 'react';

import { Box, Card, CircularProgress, Typography, useColorScheme } from '@mui/joy';
import ReactEcharts from 'echarts-for-react';

import { ghgRatingsArray, sdgRatingsArray } from '../../constants';

interface PropTypes {
  rowData: any[];
  isLoading: boolean;
}

const DistributionScatter = ({ rowData, isLoading }: PropTypes) => {
  const { mode } = useColorScheme();
  const [showChart, setShowChart] = useState<boolean>(true);

  const data = useMemo(() => {
    const sdgGhgDatas: any[] = [];
    rowData.forEach((value: any) => {
      if (typeof sdgGhgDatas[value['ghgRatingNumber']] === 'undefined') {
        sdgGhgDatas[value['ghgRatingNumber']] = {};
      }

      if (typeof sdgGhgDatas[value['ghgRatingNumber']][value['sdgRatingNumber']] === 'undefined') {
        sdgGhgDatas[value['ghgRatingNumber']][value['sdgRatingNumber']] = 1;
      } else {
        sdgGhgDatas[value['ghgRatingNumber']][value['sdgRatingNumber']] += 1;
      }
    });
    const dataOrder: any[] = [];
    sdgGhgDatas.forEach((vals, k) => {
      Object.keys(vals).forEach((ke) => {
        dataOrder.push({
          ghg: k,
          sdg: ke,
          count: vals[ke],
        });
      });
    });
    dataOrder.sort((a, b) => b.count - a.count);
    const rowNum = dataOrder.length;
    const datas: any = [];
    const ghgMedian: Array<number> = [];
    const sdgMedian: Array<number> = [];
    if (rowNum > 0) {
      let i = 0;
      let current_max = 0;
      dataOrder?.forEach((dt) => {
        const sGHG = dt.ghg ? 11 - dt.ghg : 0;
        const sSDG = dt.sdg || 0;
        if (sGHG <= 12) {
          ghgMedian.push(sGHG - 1);
          sdgMedian.push(sSDG);
          const cordinates = [sGHG - 0.5, parseFloat(sSDG) + 0.5];
          const cntRating = dt.count;
          if (i === 0) {
            current_max = cntRating;
          }
          const sizer = 30 / current_max;
          const color = '#00A0B2';
          const name = 'Number of projects';
          const drawline: any[] = [];
          const markline: any[] = [];
          const drawArea: any[] = [];
          const markArea: any[] = [];
          const dval = {
            symbolSize: 3 + cntRating * sizer,
            symbolOffset: [0, 0],
            color: color,
            name: name,
            data: [{ value: cordinates, name: cntRating + ' projects' }],
            type: 'scatter',
            markLine: { 0: markline, ...drawline },
            markArea: { 0: markArea, ...drawArea },
          };
          datas.push(dval);
          i++;
        }
      });
    }
    return datas;
  }, [rowData]);

  const option = useMemo(() => {
    const x = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    const y = [0, 1, 2, 3, 4, 5, 6];
    return {
      backgroundColor: 'transparent',
      legend: {
        show: true,
        data: [{ name: 'Number of projects' }],
        bottom: 0,
        left: 60,
        icon: 'circle',
        textStyle: {
          fontSize: '12px',
          fontFamily: "'Inter Variable', sans-serif",
        },
      },
      tooltip: {
        position: 'top',
        trigger: 'item',
        formatter: function (params: any) {
          return params.name;
        },
      },
      grid: {
        containLabel: false,
      },
      xAxis: {
        type: 'value',
        data: x,
        min: 0,
        max: 10,
        splitLine: {
          show: false,
          lineStyle: {
            color: '#B3B3B3',
            type: 'dashed',
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#B3B3B3',
            type: 'dashed',
            opacity: 0.2,
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
          interval: 0,
          overflow: 'breakAll',
          position: 'bottom',
          fontSize: 11,
          fontWeight: 500,
          fontFamily: 'lato',
          color: mode === 'dark' ? '#fff' : '#000',
          hideOverlap: true,
          align: 'center',
          padding: [0, 0, 0, -50],
          formatter: function (_value: any, index: number) {
            if (index === 0) {
              return '                                            Low GHG ';
            } else if (index === 5) {
              return 'High GHG';
            }
            return '';
          },
          textStyle: {
            fontFamily: "'Inter Variable', sans-serif",
            fontSize: '11px',
          },
        },
      },
      yAxis: [
        {
          type: 'value',
          data: y,
          min: 0,
          max: 6,
          splitLine: {
            show: false,
            lineStyle: {
              color: ['#aaa'],
              type: 'dashed',
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#B3B3B3',
              type: 'dashed',
              opacity: 0.2,
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            name: 'Y-Axis',
            overflow: 'breakAll',
            interval: 0,
            position: 'top',
            rotate: 90,
            color: mode === 'dark' ? '#fff' : '#000',
            fontSize: 11,
            fontWeight: 500,
            fontFamily: 'lato',
            hideOverlap: true,
            align: 'left',
            formatter: function (_value: any, index: number) {
              if (index === 0) {
                return 'No SDG ';
              } else if (index === 1) {
                return 'Low SDG';
              } else if (index === 5) {
                return 'High SDG';
              }
              return '';
            },
          },
        },
      ],
      series: data,
    };
  }, [data, ghgRatingsArray, sdgRatingsArray, mode]);

  useEffect(() => {
    let timerId: NodeJS.Timeout | string | number | undefined = undefined;
    setShowChart(false);
    timerId = setTimeout(() => {
      setShowChart(true);
    }, 500);
    return () => clearTimeout(timerId);
  }, [option]);

  return (
    <Card>
      <Typography fontWeight={600} fontSize={'lg'}>
        GHG and SDG Rating Distribution
      </Typography>
      {!isLoading && showChart ? (
        <Box>
          <ReactEcharts option={option} style={{ height: '400px' }} theme={mode} />
        </Box>
      ) : (
        <Box height={'400px'} width={'100%'} position={'relative'}>
          <CircularProgress
            color='primary'
            sx={{
              backgroundColor: 'transparent',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
            size='lg'
          />
        </Box>
      )}
    </Card>
  );
};

export { DistributionScatter };
