import React from 'react';

import { StyledTh } from './styles';

type ColumnProps = React.ThHTMLAttributes<HTMLTableCellElement> & { isSortable?: boolean };

const Column: React.FC<React.PropsWithChildren<ColumnProps>> = ({
  children,
  isSortable,
  ...props
}) => {
  return (
    <StyledTh isSortable={isSortable} {...props}>
      {children}
    </StyledTh>
  );
};

export default Column;
