import BuildingIcon from 'jsx:../../assets/icons/building.svg';
import CheckListIcon from 'jsx:../../assets/icons/check-list.svg';
import GenderIcon from 'jsx:../../assets/icons/gender.svg';
import HeartBrokenIcon from 'jsx:../../assets/icons/heart-broken.svg';
import HeartHandShakeIcon from 'jsx:../../assets/icons/heart-handshake.svg';
import HomeIcon from 'jsx:../../assets/icons/home.svg';
import HumanRightsIcon from 'jsx:../../assets/icons/human-rights.svg';
import LaborCartIcon from 'jsx:../../assets/icons/labor-cart.svg';
import HammerIcon from 'jsx:../../assets/icons/law-hammer.svg';
import LotusIcon from 'jsx:../../assets/icons/lotus.svg';

export const screeningList = [
  {
    id: '1',
    icon: <HammerIcon />,
    name: 'Access to Justice',
  },
  {
    id: '2',
    icon: <LotusIcon />,
    name: 'Biodiversity Conservation and Sustainable Management',
  },
  {
    id: '3',
    icon: <HeartBrokenIcon />,
    name: 'Community Health, Safety and Security',
  },
  {
    id: '4',
    icon: <HomeIcon />,
    name: 'Cultural Heritage',
  },
  {
    id: '5',
    icon: <GenderIcon />,
    name: 'Gender Equity',
  },
  {
    id: '6',
    icon: <HumanRightsIcon />,
    name: 'Human Rights and Indigenous Peoples',
  },
  {
    id: '7',
    icon: <LaborCartIcon />,
    name: 'Labor Rights and Working Conditions',
  },
  {
    id: '8',
    icon: <BuildingIcon />,
    name: 'Land Rights and Involuntary Resettlements',
  },
  {
    id: '9',
    icon: <CheckListIcon />,
    name: 'Resource Efficiency',
  },
  {
    id: '10',
    icon: <HeartHandShakeIcon />,
    name: 'Transparency, Participation and Benefit Sharing',
  },
];
