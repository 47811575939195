import { AnalyticsBrowser } from '@segment/analytics-next';

const segmentAnalytics = AnalyticsBrowser.load(
  {
    writeKey: `${process.env.SEGMENT_TRACKING_WRITE_KEY}`,
    cdnURL: `${process.env.SEGMENT_CDN_PROXY}`,
  },
  {
    integrations: {
      'Segment.io': {
        apiHost: `${process.env.SEGMENT_API_PROXY}`,
        protocol: 'https',
      },
    },
  }
);

export default segmentAnalytics;
