import React, { useMemo } from 'react';

import { Typography, Stack } from '@mui/joy';
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';

import { PaginationState } from '../../utils/hooks/usePagination/types';

import Row from './Row';
import { StyledFooter, StyledIconButton, StyledTd } from './styles';

interface FooterProps {
  paginationState: PaginationState;
  span: number;
  isLoading?: boolean;
}

const Footer: React.FC<FooterProps> = ({ paginationState, span }) => {
  const { page, totalPages, itemsRange, currentPageSet, prevPage, nextPage, getPageSet, setPage } =
    paginationState;

  const pages = useMemo(() => getPageSet(), [currentPageSet, totalPages]);
  const showPrev = useMemo(() => page !== 1, [page]);
  const showNext = useMemo(() => page !== totalPages, [page, totalPages]);
  return (
    <StyledFooter>
      <Row style={{ borderCollapse: 'collapse' }}>
        <StyledTd colSpan={span} sx={{ cursor: 'default !important' }}>
          <Stack direction={'row'} gap={2} alignItems={'center'} px={2}>
            <Typography level='body-xs' fontWeight={400} color='primary'>
              {itemsRange} Items
            </Typography>
            {pages.length > 1 && (
              <Stack direction={'row'} gap={0.2} alignItems={'center'}>
                {showPrev && (
                  <StyledIconButton variant='plain' size='sm' onClick={() => setPage(1)}>
                    <FiChevronsLeft />
                  </StyledIconButton>
                )}
                {showPrev && (
                  <StyledIconButton variant='plain' size='sm' onClick={prevPage}>
                    <FiChevronLeft />
                  </StyledIconButton>
                )}
                <Stack direction={'row'} gap={1}>
                  {pages.map((pageValue) => (
                    <StyledIconButton
                      size='sm'
                      isSelected={page === pageValue}
                      onClick={() => setPage(pageValue)}
                    >
                      <Typography level='body-xs' fontWeight={700} color='primary'>
                        {pageValue}
                      </Typography>
                    </StyledIconButton>
                  ))}
                </Stack>
                {showNext && (
                  <StyledIconButton variant='plain' size='sm' onClick={nextPage}>
                    <FiChevronRight />
                  </StyledIconButton>
                )}
                {showNext && (
                  <StyledIconButton variant='plain' size='sm' onClick={() => setPage(totalPages)}>
                    <FiChevronsRight />
                  </StyledIconButton>
                )}
              </Stack>
            )}
          </Stack>
        </StyledTd>
      </Row>
    </StyledFooter>
  );
};

export default Footer;
