import { useEffect, useMemo, useRef } from 'react';

import { Stack, styled } from '@mui/joy';

import { NormaliseCSSWrapper } from './HTMLRenderer';

interface PropTypes {
  title: string;
  htmlContent: string;
}

const HTMLTableStylesWrapper = styled('div')(({ theme }) => ({
  table: {
    tr: {
      '&:nth-child(1)': {
        fontWeight: 900,
      },
      th: {
        padding: theme.spacing(1),
        border: '1px solid #4D4D4D',
        verticalAlign: 'middle',
      },
      td: {
        padding: theme.spacing(1),
        border: '1px solid #4D4D4D',
        verticalAlign: 'middle',
      },
    },
  },
}));

const parser = new DOMParser();

const HTML = ({ htmlContent, title }: PropTypes) => {
  const parsedHTML = useMemo(() => parser.parseFromString(htmlContent, 'text/html'), [htmlContent]);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const tableDataItems = parsedHTML?.getElementsByTagName('td');
    for (let i = 0; i < tableDataItems?.length; i++) {
      if (tableDataItems?.[i]?.innerText?.toLowerCase() === 'increasing') {
        tableDataItems[i].style.color = '#90B979';
      }
      if (tableDataItems?.[i]?.innerText?.toLowerCase() === 'decreasing') {
        tableDataItems[i].style.color = '#D1635E';
      }
      if (tableDataItems?.[i]?.innerText?.toLowerCase() === 'flat to decreasing') {
        tableDataItems[i].style.color = '#D1635E';
      }
      if (Number(tableDataItems?.[i]?.innerText?.toLowerCase()) >= 0) {
        tableDataItems[i].style.color = '#000';
        tableDataItems[i].style.backgroundColor = '#90B979';
      }
      if (
        Number(tableDataItems?.[i]?.innerText?.toLowerCase()) >= -0.4 &&
        Number(tableDataItems?.[i]?.innerText?.toLowerCase()) <= -0.1
      ) {
        tableDataItems[i].style.color = '#000';
        tableDataItems[i].style.backgroundColor = '#FCD36C';
      }
      if (Number(tableDataItems?.[i]?.innerText?.toLowerCase()) <= -0.5) {
        tableDataItems[i].style.color = '#000';
        tableDataItems[i].style.backgroundColor = '#D1635E';
      }
    }

    const table = parsedHTML?.getElementsByTagName('table')?.[0];
    if (!!htmlContent && !!parsedHTML && table) ref?.current?.appendChild(table);
  }, [parsedHTML, ref]);
  return (
    <Stack>
      <NormaliseCSSWrapper>
        <div dangerouslySetInnerHTML={{ __html: title }} />
      </NormaliseCSSWrapper>
      <HTMLTableStylesWrapper>
        <div ref={ref} />
      </HTMLTableStylesWrapper>
    </Stack>
  );
};

export { HTML };
