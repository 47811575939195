import { atom } from 'recoil';

interface ProjectState {
  projectData: any;
  blogs: any;
  resources: any;
  essRatingData: any;
  projectComparisonData: any;
  isLoading: boolean;
  projectCreditingData: any;
}

export const defaultProjectState = {
  projectData: null,
  essRatingData: null,
  projectComparisonData: null,
  blogs: [],
  resources: [],
  isLoading: true,
  projectCreditingData: null,
};

export const projectState = atom<ProjectState>({
  key: 'ProjectState',
  default: {
    ...defaultProjectState,
  },
});
