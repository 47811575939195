import { setRecoil } from 'recoil-nexus';

import { userState } from '../../stores/user';

import { contact } from './endpoints';

import { makeAuthenticatedPostRequest } from './index';

export const logout = () => {
  setRecoil(userState, null);
};

export const sendUpgradeRequest = async () => {
  return await makeAuthenticatedPostRequest(contact, {
    message: 'Request for Upgrade',
  });
};
