import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { userState } from '../../../stores/user';

export const useFetchUserPermissions = () => {
  const currentUserInfo = useRecoilValue(userState);

  const hasPermissionForReadMore = useMemo(() => {
    return currentUserInfo?.user_permissions?.includes('platform:deep_dive_access');
  }, [currentUserInfo]);

  const hasPermissionForWhereToBuy = useMemo(() => {
    return currentUserInfo?.project_access_type !== 'l0_regular';
  }, [currentUserInfo]);

  const hasPermissionForRetirementInsights = useMemo(() => {
    return currentUserInfo?.project_access_type !== 'l0_regular';
  }, [currentUserInfo]);

  return {
    hasPermissionForReadMore,
    hasPermissionForWhereToBuy,
    hasPermissionForRetirementInsights,
  };
};
