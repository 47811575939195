import React, { forwardRef } from 'react';

import { StyledTr } from './styles';

type RowProps = React.HTMLAttributes<HTMLTableRowElement>;

const Row = forwardRef<HTMLTableRowElement, RowProps>(({ children, ...props }, ref) => {
  return (
    <StyledTr ref={ref} {...props}>
      {children}
    </StyledTr>
  );
});

export default Row;
