import { brand } from './brand';

export const dark = {
  divider: '#919599A8',
  focusVisible: brand.primary.dark['700'],
  background: {
    backdrop: '#f5f7fa40',
    body: brand.common.black,
    level1: brand.common.black,
    level2: brand.neutral['700'],
    level3: brand.primary.dark['300'],
    popup: brand.common.black,
    surface: brand.neutral['900'],
    tooltip: brand.neutral['600'],
  },
  text: {
    icon: brand.function.dark.neutral['90'],
    primary: brand.common.white,
    secondary: brand.function.dark.neutral['80'],
    tertiary: brand.neutral['400'],
  },
  danger: {
    darkChannel: '#7d1212',
    lightChannel: '#f7c5c5',
    outlinedActiveBg: brand.danger['700'],
    outlinedBorder: brand.danger['700'],
    outlinedColor: brand.danger['200'],
    outlinedDisabledBorder: brand.neutral['800'],
    outlinedDisabledColor: brand.neutral['500'],
    outlinedHoverBg: brand.danger['800'],
    plainActiveBg: brand.danger['700'],
    plainColor: brand.danger['300'],
    plainDisabledColor: brand.neutral['500'],
    plainHoverBg: brand.danger['800'],
    softActiveBg: brand.danger['600'],
    softActiveColor: brand.danger['100'],
    softBg: brand.danger['800'],
    softColor: brand.danger['200'],
    softDisabledBg: brand.neutral['800'],
    softDisabledColor: brand.neutral['500'],
    softHoverBg: brand.danger['700'],
    solidActiveBg: brand.danger['700'],
    solidBg: brand.danger['500'],
    solidColor: brand.common.white,
    solidDisabledBg: brand.neutral['800'],
    solidDisabledColor: brand.neutral['500'],
    solidHoverBg: brand.danger['600'],
  },
  neutral: {
    darkChannel: '#32383e',
    lightChannel: '#dde7ee',
    mainChannel: '#636b74',
    outlinedActiveBg: brand.neutral['700'],
    outlinedBorder: brand.neutral['600'],
    outlinedColor: brand.neutral['200'],
    outlinedDisabledBorder: brand.neutral['800'],
    outlinedDisabledColor: brand.neutral['500'],
    outlinedHoverBg: brand.neutral['800'],
    plainActiveBg: brand.neutral['700'],
    plainColor: brand.neutral['300'],
    plainDisabledColor: brand.neutral['500'],
    plainHoverBg: brand.neutral['800'],
    plainHoverColor: brand.neutral['300'],
    softActiveBg: brand.neutral['600'],
    softActiveColor: brand.neutral['100'],
    softBg: brand.neutral['800'],
    softColor: brand.neutral['200'],
    softDisabledBg: brand.neutral['800'],
    softDisabledColor: brand.neutral['500'],
    softHoverBg: brand.neutral['700'],
    solidActiveBg: brand.neutral['700'],
    solidBg: brand.neutral['500'],
    solidColor: brand.common.white,
    solidDisabledBg: brand.neutral['800'],
    solidDisabledColor: brand.neutral['500'],
    solidHoverBg: brand.neutral['600'],
  },
  primary: {
    darkChannel: '#0a470a',
    mainChannel: '#1f7a1f',
    lightChannel: '#c7f7c7',
    outlinedActiveBg: brand.primary.dark['600'],
    outlinedBorder: brand.function.dark.neutral['20'],
    outlinedColor: brand.neutral['800'],
    outlinedDisabledBorder: brand.neutral['800'],
    outlinedDisabledColor: brand.neutral['500'],
    outlinedHoverBg: brand.primary.dark['200'],
    outlinedHoverBorder: brand.function.dark.neutral['70'],
    plainActiveBg: brand.primary.light['500'],
    plainColor: brand.common.white,
    plainDisabledColor: brand.neutral['500'],
    plainHoverBg: brand.primary.light['600'],
    softActiveBg: brand.primary.light['500'],
    softActiveColor: brand.ocean['100'],
    softBg: brand.primary.light['500'],
    softColor: brand.ocean['200'],
    softDisabledBg: brand.neutral['800'],
    softDisabledColor: brand.neutral['500'],
    softHoverBg: brand.primary.light['600'],
    solidActiveBg: brand.primary.light['700'],
    solidBg: brand.primary.dark['600'],
    solidColor: brand.common.white,
    solidDisabledBg: brand.neutral['800'],
    solidDisabledColor: brand.neutral['500'],
    solidHoverBg: brand.primary.dark['500'],
  },
  success: {
    darkChannel: '#0a470a',
    lightChannel: '#c7f7c7',
    mainChannel: '#1f7a1f',
    outlinedActiveBg: brand.primary.light['800'],
    outlinedBorder: brand.neutral['700'],
    outlinedColor: brand.primary.light['200'],
    outlinedDisabledBorder: brand.neutral['800'],
    outlinedDisabledColor: brand.neutral['500'],
    outlinedHoverBg: brand.primary.light['700'],
    plainActiveBg: brand.primary.light['700'],
    plainColor: brand.primary.light['300'],
    plainDisabledColor: brand.neutral['500'],
    plainHoverBg: brand.primary.light['700'],
    softActiveBg: brand.primary.light['600'],
    softActiveColor: brand.primary.light['100'],
    softBg: brand.primary.light['800'],
    softColor: brand.primary.light['200'],
    softDisabledBg: brand.neutral['800'],
    softDisabledColor: brand.neutral['500'],
    softHoverBg: brand.primary.light['700'],
    solidActiveBg: brand.primary.light['600'],
    solidBg: brand.primary.light['400'],
    solidColor: brand.common.white,
    solidDisabledBg: brand.neutral['800'],
    solidDisabledColor: brand.neutral['500'],
    solidHoverBg: brand.primary.light['500'],
  },
  warning: {
    darkChannel: '#492b08',
    lightChannel: '#fce1c2',
    mainChannel: '#9a5b13',
    outlinedActiveBg: brand.warning['700'],
    outlinedBorder: brand.warning['700'],
    outlinedColor: brand.warning['200'],
    outlinedDisabledBorder: brand.neutral['800'],
    outlinedDisabledColor: brand.neutral['500'],
    outlinedHoverBg: brand.warning['800'],
    plainActiveBg: brand.warning['700'],
    plainColor: brand.warning['700'],
    plainDisabledColor: brand.neutral['500'],
    plainHoverBg: brand.warning['900'],
    softActiveBg: brand.warning['600'],
    softActiveColor: brand.warning['100'],
    softBg: brand.warning['800'],
    softColor: brand.warning['200'],
    softDisabledBg: brand.neutral['800'],
    softDisabledColor: brand.neutral['500'],
    softHoverBg: brand.warning['700'],
    solidActiveBg: brand.warning['700'],
    solidBg: brand.warning['500'],
    solidColor: brand.common.white,
    solidDisabledBg: brand.neutral['800'],
    solidDisabledColor: brand.neutral['500'],
    solidHoverBg: brand.warning['600'],
  },
  GHG: {
    E: brand.function.dark.red['90'],
    'E+': brand.function.dark.red['100'],
    D: brand.function.dark.orange['90'],
    'D+': brand.function.dark.orange['100'],
    C: brand.function.dark.yellow['90'],
    'C+': brand.function.dark.yellow['100'],
    B: brand.function.dark.lime['90'],
    'B+': brand.function.dark.lime['100'],
    A: brand.function.light.green['90'],
    'A+': brand.function.dark.green['100'],
  },
  GHGV2: {
    E: '#0F3861',
    'E+': brand.ocean[700],
    D: brand.ocean[600],
    'D+': brand.ocean[500],
    C: '#257FD9',
    'C+': brand.ocean[400],
    B: '#6BA9E8',
    'B+': brand.ocean[300],
    A: brand.ocean[200],
    'A+': brand.ocean[100],
  },
  SDG: {
    '1': brand.function.dark.green['60'],
    '2': brand.function.dark.green['70'],
    '3': brand.function.dark.green['80'],
    '4': brand.function.dark.green['90'],
    '5': brand.function.dark.green['100'],
  },
  SDGV2: {
    '1': brand.ocean[700],
    '2': brand.ocean[500],
    '3': brand.ocean[400],
    '4': brand.ocean[300],
    '5': brand.ocean[100],
    'No cert': '#9541BC',
  },
  border: {
    border: brand.function.dark.neutral['30'],
  },
  illustrations: {
    illustration_border_green: brand.base.dark.forest['100'],
    illustration_border_grey: brand.function.dark.neutral['70'],
  },
  SDGDisplay: {
    '1': brand.function.dark.lime['60'],
    '2': brand.function.dark.lime['70'],
    '3': brand.function.dark.lime['80'],
    '4': brand.function.dark.lime['90'],
    '5': brand.function.dark.lime['100'],
    '6': brand.function.dark.green['60'],
    '7': brand.function.dark.green['70'],
    '8': brand.function.dark.green['80'],
    '9': brand.function.dark.green['90'],
    '10': brand.function.dark.green['100'],
    '11': brand.sky.dark['200'],
  },
  graph: {
    issued: brand.sky.dark['100'],
    retired: brand.function.dark.yellow['100'],
    forestLand: brand.function.dark.green['80'],
    householdCommunity: brand.function.dark.yellow['80'],
    manufacturingIndustry: brand.function.dark.ocean,
    renewableEnergy: brand.sky.dark['100'],
    waste: brand.function.dark.orange['80'],
  },
  expert: {
    light: brand.expert.dark['050'],
    dark: brand.expert.dark['100'],
  },
  blur: {
    '25%': brand.blur.dark,
  },
  linearGradient: {
    special: brand.linearGradient.dark,
  },
  hyperlink: {
    cell: brand.hyperlink.cell.dark,
    text: brand.hyperlink.text.dark,
    underline: brand.hyperlink.underline.dark,
  },
};
