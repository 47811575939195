import { useEffect, useState, useRef } from 'react';

export const useExecuteCallbackOnIntersection = (
  ref: React.RefObject<HTMLDivElement>,
  callback: () => void,
  dependency?: any,
  rootMargin?: string
) => {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observerRef = useRef<IntersectionObserver>();

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) setIsOnScreen(true);
      },
      { rootMargin }
    );
  }, []);

  useEffect(() => {
    if (ref.current) {
      observerRef.current?.observe(ref.current);
    }
    return () => {
      observerRef.current?.disconnect();
    };
  }, [ref, dependency]);

  useEffect(() => {
    if (isOnScreen) callback();
  }, [isOnScreen]);

  return isOnScreen;
};
