import { memo } from 'react';

import { Grid, Stack, Skeleton } from '@mui/joy';

const DescriptionLoader = memo(() => {
  return (
    <Grid gap={1} direction={'column'} display={'flex'}>
      <Stack gap={1} direction={'column'} width={'100%'}>
        <Stack direction={'row'} gap={1}>
          <Skeleton
            variant='rectangular'
            width={'75px'}
            height={'24px'}
            sx={{
              borderRadius: '3rem',
            }}
          />
        </Stack>
        <Skeleton variant='rectangular' width={'100%'} height={'48px'} />
        <Stack>
          <Skeleton variant='rectangular' width={'150px'} height={'20px'} />
        </Stack>
        <Stack>
          <Skeleton
            variant='rectangular'
            width={'100%'}
            height={'350px'}
            sx={{
              width: '100%',
              height: '60vh',
            }}
          ></Skeleton>
        </Stack>
        <Stack width={'100%'} gap={3}>
          {Array(5)
            .fill('')
            .map(() => {
              return Array(4)
                .fill('')
                .map(() => {
                  return <Skeleton variant='rectangular' width={'100%'} height={'48px'} />;
                });
            })}
        </Stack>
      </Stack>
    </Grid>
  );
});

export default DescriptionLoader;
