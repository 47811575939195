import React from 'react';

import { Box, Button, Card, Chip, Grid, Stack, styled, Typography } from '@mui/joy';
import { CaretLeftIcon } from '@radix-ui/react-icons';
import { useNavigate } from 'react-router-dom';

import Image from '../../../components/Image';
import { getAssetURL } from '../../../services/axios/endpoints';
import { theme } from '../../../theme';
import { dateFormat } from '../../../utils/functions/date';
import { DistributionGraph } from '../../home/components/DistributionGraph';

const StyledImage = styled(Image)(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  borderRadius: '24px',
}));

const AuthorImage = styled('img')(() => ({
  width: '60px',
  height: '60px',
  objectFit: 'cover',
  borderRadius: '100%',
}));

export const PinnedBlogDescription = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        paddingY: 5,
        mx: 'auto',
        maxWidth: '1024px',
      }}
    >
      <Stack alignItems={'flex-start'} width={'100%'} position={'relative'} left={8} mb={2} px={5}>
        <Button
          startDecorator={<CaretLeftIcon style={{ fontSize: theme.vars.fontSize.xl2 }} />}
          variant='plain'
          onClick={() => {
            navigate(-1);
          }}
        >
          <Typography fontSize={'sm'} fontWeight={'sm'} color='primary'>
            Return
          </Typography>
        </Button>
      </Stack>
      <Card sx={{ width: '90%', padding: '24px 40px', mx: 'auto' }}>
        <Grid container gap={1} direction={'column'} sx={{ height: '100%', minHeight: '100vh' }}>
          <React.Fragment>
            <Grid xs={12} gap={1} direction={'row'} display={'flex'}>
              <Chip
                variant='outlined'
                color='neutral'
                sx={{
                  padding: '0px 8px',
                }}
              >
                <Typography fontWeight={'md'} fontSize={'md'}>
                  Calyx ratings
                </Typography>
              </Chip>
            </Grid>
            <Grid xs={12} justifyContent={'flex-start'}>
              <Typography level='h1' fontWeight={'md'} fontSize={'3rem'}>
                Current Calyx Risk Ratings Distribution
              </Typography>
            </Grid>
            <Grid xs={12} justifyContent={'flex-start'}>
              <Typography level='h2' fontWeight={'md'} fontSize={'xl'}>
                {dateFormat(new Date().toDateString()).mmddyyyy}
              </Typography>
            </Grid>
            <Grid xs={12} justifyContent={'flex-start'} pt={1}>
              <StyledImage
                src={getAssetURL('howrisky.jpg')}
                alt={'How risky is the carbon market?'}
              />
            </Grid>
          </React.Fragment>
          <Grid xs={12} justifyContent={'flex-start'} pt={1}>
            <Stack spacing={3}>
              <Typography>
                Calyx Global has to date assessed and rated 600+ carbon projects across more than 20
                different project types. Projects rated include removals and avoided emissions
                across nature-based to technology-based project types, from 8 different crediting
                standards and over 50 methodologies. When accounting for ratings of different carbon
                credit vintages, we have over 650 GHG ratings.
              </Typography>
              <Typography>
                We find variability of quality no matter what parameter is selected (type, standard,
                country, CORSIA eligibility and more). Sometimes the variability goes all the way
                from high risk (i.e. poor quality) to low risk, sometimes it is less broad, but a
                range (distribution) is nearly always there. While quality credits can be found in
                today’s market, there are few credit types that consistently deliver high quality.
              </Typography>
              <Typography>
                The same remark is valid for the contribution to SDGs. Among the 350+ certified
                projects we've rated (e.g., CCB, SD VISta, Gold Standard for the Global Goals), we
                find differences in the degree of impact and level of evidence provided. This leads
                to Calyx SDG Ratings from +1 to +5, with +5 being the best rating that can be
                obtained in our framework.
              </Typography>
              <Typography>
                Below are two charts that show a distribution of Calyx Ratings. These charts will be
                updated as our coverage grows.
              </Typography>
              <Card>
                <Typography level='h3'>600+ projects rated in total.</Typography>
              </Card>
            </Stack>
            <Stack mt={5} gap={5}>
              <Stack>
                <Typography fontWeight={'xl'} fontSize={'lg'}>
                  GHG Risk Rating Distribution
                </Typography>
                <DistributionGraph type={'ghg'} />
              </Stack>
              <Stack>
                <Typography fontWeight={'xl'} fontSize={'lg'}>
                  SDG Impact Rating Distribution
                </Typography>
                <DistributionGraph type={'sdg'} />
              </Stack>
            </Stack>
            <Card sx={{ marginY: 6, padding: 3 }}>
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                <AuthorImage alt='Calyx Global Logo' src={getAssetURL('calyx.png')} />
                <Stack>
                  <Typography fontSize={'md'} fontWeight={'xl'}>
                    About the author
                  </Typography>
                  <Typography fontSize={'xl3'} fontWeight={'xl'}>
                    Calyx Global
                  </Typography>
                </Stack>
              </Stack>
              <Typography fontWeight={'xl'}>
                This article includes insights and input from multiple experts in Calyx Global.
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
