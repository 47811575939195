import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { Button, Grid, styled, Typography, Link, Chip, Box, Stack, Card } from '@mui/joy';
import { CaretLeftIcon } from '@radix-ui/react-icons';
import { useQuery } from '@tanstack/react-query';
import { FiFileText } from 'react-icons/fi';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Image from '../../../components/Image';
import { track } from '../../../services/analytics';
import { EVENT_BLOG_PAGE, PGV_blog_detail } from '../../../services/analytics/events';
import { makeAuthenticatedGetRequest } from '../../../services/axios';
import { blogs, getAssetFileUrl, getAssetURL, resources } from '../../../services/axios/endpoints';
import { theme } from '../../../theme';
import { BLOG_TYPE, RESOURCE_TYPE } from '../../../utils/constants';
import { dateFormat } from '../../../utils/functions/date';
import { updateImageBaseUrlInHTML } from '../../../utils/functions/html';
import { capitalizeFirstLetter } from '../../../utils/functions/string';
import { NormaliseCSSWrapper } from '../../project/ghg_rating/components/HTMLRenderer';

import DescriptionLoader from './skeletons/DescriptionLoader';

interface tag {
  id: string;
  tag: string;
}
interface tags {
  tag_info: tag;
}

const Tbody = styled('div')(({ theme }) => ({
  color: theme.vars.palette.text.primary,
  fontFamily: theme?.fontFamily?.body,
  fontSize: theme.vars.fontSize.md,
  fontStyle: 'normal',
  fontWeight: theme.vars.fontWeight.xs,
  lineHeight: theme.vars.lineHeight.md,
  ul: {
    paddingLeft: '32px',
    margin: `${theme.spacing(1.5)} 0px`,
    li: {
      listStyle: 'disc',
    },
  },
  'li > a': {
    color: theme.vars.palette['hyperlink']['text'],
    fontWeight: theme.vars.fontWeight.sm,
  },
  '.RichText3-paragraph': {
    color: theme.vars.palette.text.primary,
    fontFamily: theme?.fontFamily?.body,
    fontSize: theme.vars.fontSize.md,
    fontStyle: 'normal',
    fontWeight: theme.vars.fontWeight.xs,
    lineHeight: theme.vars.lineHeight.md,
    margin: `${theme.spacing(1)} 0px`,
    '& > a': {
      color: theme.vars.palette['hyperlink']['text'],
      textDecoration: 'underline',
      fontFamily: theme?.fontFamily?.body,
      fontSize: theme.vars.fontSize.md,
      fontStyle: 'normal',
      fontWeight: theme.vars.fontWeight.sm,
      lineHeight: '1.5rem',
    },
    '& > img': {
      width: '100%',
      objectFit: 'contain',
      maxHeight: '60vh',
    },
  },
  img: {
    width: '100%',
    objectFit: 'contain',
    maxHeight: '60vh',
    margin: `${theme.spacing(1)} 0px`,
  },
  '& > h4': {
    color: theme.vars.palette.text.primary,
    fontFamily: theme?.fontFamily?.body,
    fontSize: theme.vars.fontSize.xl3,
    fontWeight: theme.vars.fontWeight.md,
    fontStyle: 'normal',
    lineHeight: theme.vars.lineHeight.md,
  },
  '& > h5': {
    color: theme.vars.palette.text.primary,
    fontFamily: theme?.fontFamily?.body,
    fontSize: theme.vars.fontSize.xl2,
    fontStyle: 'normal',
    fontWeight: theme.vars.fontWeight.md,
    lineHeight: theme.vars.lineHeight.md,
    margin: `${theme.spacing(1)} 0px`,
    '& > img': {
      width: '100%',
      objectFit: 'contain',
      maxHeight: '60vh',
    },
  },
  '& > p': {
    color: theme.vars.palette.text.primary,
    fontFamily: theme?.fontFamily?.body,
    fontSize: theme.vars.fontSize.md,
    fontStyle: 'normal',
    fontWeight: theme.vars.fontWeight.xs,
    lineHeight: theme.vars.lineHeight.md,
    margin: `${theme.spacing(1)} 0px`,
    wordWrap: 'break-word',
    '& > a': {
      color: theme.vars.palette['hyperlink']['text'],
      textDecoration: 'underline',
      fontFamily: theme?.fontFamily?.body,
      fontSize: theme.vars.fontSize.md,
      fontStyle: 'normal',
      fontWeight: theme.vars.fontWeight.sm,
      lineHeight: '1.5rem',
    },
    '& > img': {
      width: '100%',
      objectFit: 'contain',
      height: 'min-content',
    },
    '& > i': {
      fontStyle: 'italic',
    },
    '& > em': {
      fontStyle: 'italic',
    },
  },
  '& > a': {
    color: theme.vars.palette['hyperlink']['text'],
    textDecoration: 'underline',
    fontFamily: theme?.fontFamily?.body,
    fontSize: theme.vars.fontSize.md,
    fontStyle: 'normal',
    fontWeight: theme.vars.fontWeight.sm,
    lineHeight: theme.vars.lineHeight.md,
    margin: `${theme.spacing(1)} 0px`,
  },
  table: {
    tr: {
      th: {
        padding: theme.spacing(2),
        border: '1px solid #4D4D4D',
        verticalAlign: 'middle',
      },
      td: {
        padding: theme.spacing(2),
        border: '1px solid #4D4D4D',
        verticalAlign: 'middle',
      },
    },
    'tr:first-child': {
      fontWeight: 700,
    },
  },
  '& > div': {
    '& > table': {
      ' & > tr': {
        '& > th': {
          padding: theme.spacing(2),
          border: '1px solid #4D4D4D',
        },
        '& > td': {
          padding: theme.spacing(2),
          border: '1px solid #4D4D4D',
        },
      },
    },
  },
  '& > ol': {
    listStyle: 'auto',
    paddingLeft: theme.spacing(4),
  },
}));

const StyledImage = styled(Image)(() => ({
  width: '100%',
  objectFit: 'contain',
  maxHeight: '100vh',
}));

export const BlogDescription = () => {
  const [type, setType] = useState<string>();
  const location = useLocation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const id = params.get('tag');

  useEffect(() => {
    if (location?.pathname.split('/').includes('resources')) {
      setType(RESOURCE_TYPE);
    } else {
      setType(BLOG_TYPE);
    }
  }, [location]);

  const { data, isLoading } = useQuery({
    queryKey: ['blogs', { id, type }],
    queryFn: () => {
      if (type === RESOURCE_TYPE) {
        return makeAuthenticatedGetRequest(`${resources}/${id}`);
      } else {
        return makeAuthenticatedGetRequest(`${blogs}/${id}`);
      }
    },
    select: (data) => data?.data?.response,
    enabled: !!id,
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const html = useMemo(() => {
    return updateImageBaseUrlInHTML(data?.post || '');
  }, [data?.post]);

  useEffect(() => {
    if (!!data?.title) {
      track(PGV_blog_detail, EVENT_BLOG_PAGE, { title: data?.title });
    }
  }, [data]);

  if (!id) return <Navigate to={'/blog'} />;

  return (
    <Box
      sx={{
        paddingY: 5,
        mx: 'auto',
        maxWidth: '1024px',
      }}
    >
      <Grid
        container
        gap={1}
        justifyContent={'flex-start'}
        direction={'column'}
        alignItems={'center'}
        sx={{ height: '100%', minHeight: '100vh' }}
      >
        <Stack
          alignItems={'flex-start'}
          width={'100%'}
          position={'relative'}
          left={8}
          mb={2}
          px={5}
        >
          <Button
            startDecorator={<CaretLeftIcon style={{ fontSize: theme.vars.fontSize.xl2 }} />}
            variant='plain'
            onClick={() => {
              navigate(-1);
            }}
          >
            <Typography fontSize={'sm'} fontWeight={'sm'} color='primary'>
              Return
            </Typography>
          </Button>
        </Stack>
        <Card sx={{ width: '90%', padding: '24px 40px', mx: 5 }}>
          <>
            {!isLoading ? (
              <React.Fragment>
                <Grid gap={1} direction={'row'} display={'flex'}>
                  {data?.tags?.map((tag: tags) => {
                    return (
                      <Chip
                        variant='outlined'
                        color='neutral'
                        sx={{
                          padding: '0px 8px',
                        }}
                      >
                        <Typography fontWeight={'md'} fontSize={'md'}>
                          {capitalizeFirstLetter(tag?.tag_info?.tag)}
                        </Typography>
                      </Chip>
                    );
                  })}
                </Grid>
                <Grid justifyContent={'flex-start'}>
                  <Typography level='h1' fontWeight={'md'} fontSize={'3rem'}>
                    {data?.title}
                  </Typography>
                </Grid>
                <Grid justifyContent={'flex-start'}>
                  <Typography level='h2' fontWeight={'md'} fontSize={'xl'}>
                    {dateFormat(data?.date).mmddyyyy}
                  </Typography>
                </Grid>
                <Grid justifyContent={'flex-start'} pt={1}>
                  <StyledImage
                    src={getAssetURL(data?.visual, type === RESOURCE_TYPE ? RESOURCE_TYPE : '')}
                    alt={data?.title}
                  />
                </Grid>
                <Grid marginTop={1}>
                  <NormaliseCSSWrapper>
                    <Tbody dangerouslySetInnerHTML={{ __html: html }} />
                  </NormaliseCSSWrapper>
                </Grid>
                {data?.file && (
                  <Grid marginTop={1}>
                    <Button startDecorator={<FiFileText />}>
                      <Link target='_blank' href={getAssetFileUrl(data?.file)}>
                        Download Resource
                      </Link>
                    </Button>
                  </Grid>
                )}
              </React.Fragment>
            ) : (
              <DescriptionLoader />
            )}
          </>
        </Card>
      </Grid>
    </Box>
  );
};
