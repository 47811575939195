export type Filter = {
  name: string;
  display_name: string;
  type?: string;
  filters: Filter[];
};

export type Section = {
  name: string;
  type?: string;
  display_name: string;
  filters: Filter[];
};

export enum FilterType {
  MULTI_SELECT = 'multi_select',
  TEXT = 'text',
}

export type FilterValue =
  | string
  | number
  | Array<string | number>
  | Record<string, string>
  | FilterObject;

export type FilterObject = {
  [key: string]: FilterValue;
};
