import { useMemo } from 'react';

import { Typography } from '@mui/joy';

interface PropTypes {
  textContent: string;
  titleType: string;
}

export const Title = ({ textContent, titleType }: PropTypes) => {
  const fontSize = useMemo(() => {
    let size = 'xl2';
    switch (titleType) {
      case 'PARAGRAPH_TITLE':
        size = 'xl2';
        break;

      case 'PARAGRAPH_SUBTITLE':
        size = 'xl';
        break;

      case 'SECTION_TITLE':
        size = 'xl3';
        break;

      case 'PAGE_TITLE':
        size = 'xl4';
        break;
    }
    return size;
  }, [titleType]);
  return (
    <Typography fontSize={fontSize} fontWeight={'xl'} color='primary'>
      {textContent}
    </Typography>
  );
};
