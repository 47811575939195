import '@fontsource/lato';
import '@fontsource-variable/inter';
import { CssBaseline } from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import { LicenseManager } from 'ag-grid-enterprise';
import { IntlProvider } from 'react-intl';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';

import { GlobalCustomCSSProvider } from './components/GlobalCustomCSSProvider';
import SnackBar from './components/SnackBar';
import AuthContext from './context/authContext';
import { Routes } from './routes';
import queryClient from './services/react-query';
import { theme } from './theme';

LicenseManager.setLicenseKey(process.env.AG_GRID_LICENSE_KEY ?? '');

function App(props: { messages?: any }) {
  return (
    <RecoilRoot>
      <RecoilNexus />
      <AuthContext>
        <QueryClientProvider client={queryClient}>
          <CssVarsProvider theme={theme} defaultMode='dark'>
            <CssBaseline />
            <GlobalCustomCSSProvider>
              <IntlProvider locale='fr' messages={props.messages} defaultLocale='en'>
                <Routes />
                <SnackBar />
              </IntlProvider>
            </GlobalCustomCSSProvider>
          </CssVarsProvider>
        </QueryClientProvider>
      </AuthContext>
    </RecoilRoot>
  );
}

export default App;
