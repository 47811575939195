import { Box, CircularProgress, Typography } from '@mui/joy';

const ProgressSemiCircle = ({
  value,
  percentage,
  total,
  size,
}: {
  value: number;
  percentage: number;
  total: number;
  size: number;
}) => {
  const halfSize = `${size / 2}px`;
  const scaledValue = percentage / 2;
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        width: size,
        height: halfSize,
        overflow: 'hidden',
        my: 3,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          display: 'inline-flex',
          top: 0,
          left: 0,
          transform: 'rotate(-90deg)',
        }}
      >
        <CircularProgress
          determinate
          sx={{
            '--CircularProgress-trackColor': (theme) => theme.palette['border']['border'],
            '--CircularProgress-size': `${size}px`,
            '--CircularProgress-progressColor': (theme) => theme.palette['SDGDisplay'][value],
          }}
          value={scaledValue}
        />
      </Box>

      <Typography
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          textAlign: 'center',
        }}
        level='body-lg'
      >
        {value}/{total}
      </Typography>
    </Box>
  );
};
export default ProgressSemiCircle;
