import { useState } from 'react';

import { Button, Card, Stack, Typography } from '@mui/joy';
import { TbBulb } from 'react-icons/tb';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { track } from '../../../../../services/analytics';
import {
  EVENT_GHG_SUMMARY_PAGE,
  EVENT_SDG_SUMMARY_PAGE,
  EVNTAPP_submit_upgrade_modal_clicked,
  EVNTAPP_upgrade_request_submitted,
  PGVAPP_submit_upgrade_modal_viewed,
} from '../../../../../services/analytics/events';
import { sendUpgradeRequest } from '../../../../../services/axios/functions';
import { snackbarState } from '../../../../../stores/snackerbar';
import { userState } from '../../../../../stores/user';

const REQUEST_UPGRADE = 'REQUEST_UPGRADE';
const SUBMIT_REQUEST = 'SUBMIT_REQUEST';
const THANKYOU_MESSAGE = 'THANKYOU_MESSAGE';

const RequestUpgradeBody = ({
  changeView,
  project,
  tab,
  projectCreditingData,
  selectedTab,
}: {
  changeView: (view: string) => void;
  project: any;
  tab: 'GHG' | 'SDG';
  projectCreditingData: any;
  selectedTab: string;
}) => {
  const handleRequest = () => {
    track(
      PGVAPP_submit_upgrade_modal_viewed,
      tab === 'SDG' ? EVENT_SDG_SUMMARY_PAGE : EVENT_GHG_SUMMARY_PAGE,
      {
        project_id: project?.projectId,
        project_tab: tab,
        project_name: project?.title,
        project_section: selectedTab,
        ghg_rating: project?.ghgRatingDetail?.grade,
        project_category: project?.projectCategory,
        project_type: project?.projectType,
        issuance_size: projectCreditingData?.issuanceSizeCategory,
      }
    );
    changeView(SUBMIT_REQUEST);
  };

  return (
    <>
      <TbBulb size={80} style={{ strokeWidth: '1px' }} />
      <Typography level='h1' fontWeight={'lg'}>
        Upgrade to dive deeper
      </Typography>
      <Typography textAlign={'center'} fontSize={'md'} color='neutral'>
        Upgrade to an Expert subscription to get access to deeper project analysis as well as
        additional benefits. To upgrade, fill out the form and our customer success team will
        contact you soon.
      </Typography>
      <Button
        onClick={handleRequest}
        sx={{ background: 'linear-gradient(270deg, #3D1A4D 0%, #8239A4 100%)' }}
      >
        Request upgrade
      </Button>
    </>
  );
};

const SubmitRequestBody = ({
  changeView,
  project,
  tab,
  projectCreditingData,
  selectedTab,
}: {
  changeView: (view: string) => void;
  project: any;
  tab: 'GHG' | 'SDG';
  projectCreditingData: any;
  selectedTab: string;
}) => {
  const userInfo = useRecoilValue(userState);
  const setSnackbar = useSetRecoilState(snackbarState);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    track(
      EVNTAPP_submit_upgrade_modal_clicked,
      tab === 'SDG' ? EVENT_SDG_SUMMARY_PAGE : EVENT_GHG_SUMMARY_PAGE,
      {
        project_id: project?.projectId,
        project_tab: tab,
        project_name: project?.title,
        project_section: selectedTab,
        ghg_rating: project?.ghgRatingDetail?.grade,
        project_category: project?.projectCategory,
        project_type: project?.projectType,
        issuance_size: projectCreditingData?.issuanceSizeCategory,
      }
    );
    setLoading(true);
    sendUpgradeRequest()
      .then(() => {
        track(
          EVNTAPP_upgrade_request_submitted,
          tab === 'SDG' ? EVENT_SDG_SUMMARY_PAGE : EVENT_GHG_SUMMARY_PAGE,
          {
            project_id: project?.projectId,
            project_tab: tab,
            project_name: project?.title,
          }
        );
        changeView(THANKYOU_MESSAGE);
      })
      .catch(() => {
        setSnackbar({ message: 'Something went wrong. Please try again.', color: 'danger' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    track(
      EVNTAPP_submit_upgrade_modal_clicked,
      tab === 'SDG' ? EVENT_SDG_SUMMARY_PAGE : EVENT_GHG_SUMMARY_PAGE,
      {
        project_id: project?.projectId,
        project_tab: tab,
        project_name: project?.title,
      }
    );
    changeView(REQUEST_UPGRADE);
  };

  return (
    <>
      <Typography level='h1' fontWeight={'lg'}>
        Submit your request
      </Typography>
      <Typography textAlign={'center'} fontSize={'md'} color='neutral'>
        Your upgrade request will be sent to our team. We will reach out to you shortly at{' '}
        {userInfo?.email}.
      </Typography>
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        <Button onClick={handleCancel} sx={{ border: '1px solid #8239A4' }} variant='plain'>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          sx={{ background: 'linear-gradient(270deg, #3D1A4D 0%, #8239A4 100%)' }}
          disabled={loading}
        >
          Submit
        </Button>
      </Stack>
    </>
  );
};

const ThankyouMessageBody = () => {
  return (
    <>
      <Typography level='h1' fontWeight={'lg'}>
        Thank you! Your request is on its way
      </Typography>
      <Typography textAlign={'center'} fontSize={'md'} color='neutral'>
        Your request has been successfully submitted. Our team will reach out to you shortly.
      </Typography>
    </>
  );
};

const renderView = (
  view: string,
  changeView: (view: string) => void,
  project: any,
  tab: 'GHG' | 'SDG',
  projectCreditingData: any,
  selectedTab: string
) => {
  switch (view) {
    case REQUEST_UPGRADE:
      return (
        <RequestUpgradeBody
          changeView={changeView}
          project={project}
          tab={tab}
          projectCreditingData={projectCreditingData}
          selectedTab={selectedTab}
        />
      );
    case SUBMIT_REQUEST:
      return (
        <SubmitRequestBody
          changeView={changeView}
          project={project}
          tab={tab}
          projectCreditingData={projectCreditingData}
          selectedTab={selectedTab}
        />
      );
    case THANKYOU_MESSAGE:
      return <ThankyouMessageBody />;
  }
};

export const UpgradeToReadMoreModal = ({
  project,
  tab,
  projectCreditingData,
  selectedTab,
}: {
  project: any;
  tab: 'GHG' | 'SDG';
  projectCreditingData: any;
  selectedTab;
}) => {
  const [currentView, setCurrentView] = useState<string>(REQUEST_UPGRADE);
  const changeView = (view: string) => setCurrentView(view);
  return (
    <Card
      sx={{
        width: '700px',
        border: '1px solid #A749D3',
        position: 'absolute',
        top: '60px',
        left: '50%',
        transform: 'translateX(-50%)',
        padding: '24px',
        zIndex: 9,
      }}
    >
      <Stack alignItems={'center'} spacing={2}>
        {renderView(currentView, changeView, project, tab, projectCreditingData, selectedTab)}
      </Stack>
    </Card>
  );
};
