import { useTheme } from '@mui/joy';

export interface ThemedFrameHook {
  updateIframeTheme: (frameNode: HTMLIFrameElement) => void;
}

const useThemedFrame = (): ThemedFrameHook => {
  const theme = useTheme();

  const updateIframeTheme = (frameNode: HTMLIFrameElement) => {
    const doc = frameNode?.contentDocument;
    const style = document.createElement('style');
    if (doc) {
      style.textContent = `
            body { 
                background: ${theme.palette.background.surface};
            } 
            label {
                color: ${theme.palette.text.primary} !important;
            }
            input[type='submit'] {
                background: ${theme.palette.primary.solidBg} !important;
                color: ${theme.palette.primary.solidColor} !important; 
                border-radius: ${theme.radius.sm} !important;
            }
            p {
              background: ${theme.palette.background.surface} !important;
              color: ${theme.palette.text.primary} !important;
            }
        `;
      frameNode.contentDocument.head.appendChild(style);
    }
  };

  return { updateIframeTheme };
};

export default useThemedFrame;
