import { useEffect, useRef, useCallback } from 'react';

type UseInfiniteScrollProps = {
  scrollParentRef: React.RefObject<HTMLDivElement>;
  fetchMoreOnBottomReached: (containerRefElement?: HTMLDivElement | null) => void;
  enabled?: boolean;
};

const useInfiniteScroll = ({
  scrollParentRef,
  fetchMoreOnBottomReached,
  enabled = true,
}: UseInfiniteScrollProps) => {
  const sentinelRef = useRef<HTMLDivElement | null>(null);

  const createObserver = useCallback(() => {
    if (scrollParentRef.current && sentinelRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && enabled) {
            fetchMoreOnBottomReached(scrollParentRef.current);
          }
        },
        {
          root: scrollParentRef.current,
          rootMargin: '0px 0px 400%',
          threshold: 0,
        }
      );

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [enabled, fetchMoreOnBottomReached, sentinelRef.current]);

  useEffect(() => {
    const cleanup = createObserver();
    return () => {
      if (cleanup) cleanup();
    };
  }, [createObserver, enabled]);

  return sentinelRef;
};

export default useInfiniteScroll;
