import React from 'react';

import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/joy';
import { IoMdInformationCircleOutline } from 'react-icons/io';

import { ErrorType, SignUpInfo } from '../SignUp';

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  fontSize: theme.vars.fontSize.sm,
  fontWeight: 'normal',
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: theme.vars.fontSize.sm,
  fontWeight: theme.vars.fontWeight.md,
}));

const PersonalForm: React.FC<{
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error: ErrorType;
  data: SignUpInfo;
}> = ({ handleChange, error, data }) => {
  const theme = useTheme();
  return (
    <>
      <Typography fontSize={'xl'} fontWeight={'xl'} color='primary'>
        Enter your personal details
      </Typography>
      <FormControl error={!!error.firstName} onChange={handleChange}>
        <StyledFormLabel>First Name</StyledFormLabel>
        <Input type='name' name='firstName' defaultValue={data.firstName} />
        {error.firstName && (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <IoMdInformationCircleOutline
              size={14}
              style={{ marginTop: '6px', color: theme.vars.palette.danger[500] }}
            />
            <StyledFormHelperText>{error.firstName}</StyledFormHelperText>
          </Stack>
        )}
      </FormControl>
      <FormControl error={!!error.lastName} onChange={handleChange}>
        <StyledFormLabel>Last Name</StyledFormLabel>
        <Input type='name' name='lastName' defaultValue={data.lastName} />
        {error.lastName && (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <IoMdInformationCircleOutline
              size={14}
              style={{ marginTop: '6px', color: theme.vars.palette.danger[500] }}
            />
            <StyledFormHelperText>{error.lastName}</StyledFormHelperText>
          </Stack>
        )}
      </FormControl>
      <FormControl error={!!error.email} onChange={handleChange}>
        <StyledFormLabel>Email address</StyledFormLabel>
        <Input type='email' name='email' defaultValue={data.email} />
        {error.email && (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <IoMdInformationCircleOutline
              size={14}
              style={{ marginTop: '6px', color: theme.vars.palette.danger[500] }}
            />
            <StyledFormHelperText>{error.email}</StyledFormHelperText>
          </Stack>
        )}
      </FormControl>
    </>
  );
};

export { PersonalForm };
